import React from 'react'
import Page from '../Page'
import { ContentBlock, ContentBlockContainer } from '../common'
import Link from '../style-components/Link'

const Contact = () => {
  return (
    <Page
      pageTitle="Contactpagina"
      imageUrl="https://www.harmonieviversel.be/uploads/header/cover_harmonie1.jpg1583190402"
    >
      <ContentBlock textAlign="left">
        <h2>Lokaal</h2>
        <p>
          Ons lokaal ( Sporthal Olympia ) is gelegen in de Pastoor van
          Mierlolaan.
        </p>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2509.1893172569235!2d5.278128715967722!3d51.03112405323454!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c125171b9d1ebb%3A0x7a67af942ddf08f6!2sSporthal%20Heusden!5e0!3m2!1snl!2sbe!4v1646859377439!5m2!1snl!2sbe"
          width="100%"
          height="450"
          style={{ border: 0 }}
          loading="lazy"
        ></iframe>
      </ContentBlock>
      <ContentBlock textAlign="left">
        <h2>Heb je een vraag, wil je lid worden?</h2>
        <p>
          Bekijk <Link to="lid-worden">deze</Link> pagina voor meer info over
          lidmaatschap.
          <br />
          Je kan ons altijd bereken via het email adres :{' '}
          <a href="mailto:info@ttcsmash.be">info@ttcsmash.be</a> <br />
          Je kan ons ook bellen via het nummer :{' '}
          <a href="tel=0472452941">0472/452941</a>{' '}
        </p>
      </ContentBlock>
    </Page>
  )
}

export default Contact

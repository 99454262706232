import React from 'react'
import styled from 'styled-components'
import propTypes from 'prop-types'

import { ContentBlock, SingleLightGallery } from '../../common'

const Title = styled.h2`
  margin-bottom: 2rem;
`

const Footer = styled.div`
  color: #717171;
  font-size: ${(props) => props.theme.newsFooter.fontSize};
  line-height: ${(props) => props.theme.newsFooter.lineHeight};
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #e5e5e5;
  padding: 1rem 0;
  width: 100%;
  align-items: center;
  margin-top: 2rem;
`

const Container = styled.div`
  height: 100%;
  div {
    white-space: initial !important;
  }
`

const FooterLeft = styled.div`
  display: flex;
`

const ContentWrapper = styled.div`
  display: flex;
  flex-flow: column wrap;
  height: 100%;
  justify-content: space-between;
  div,
  p {
    font-size: ${(props) => props.theme.text.normal.fontSize} !important;
    line-height: ${(props) => props.theme.text.normal.lineHeight} !important;
  }
`

const FooterRight = styled.div``

const NewsArticle = ({ children, title, image, footer }) => {
  const date = footer.date
  const day = ('0' + date.getDate()).slice(-2)
  const month = ('0' + parseInt(date.getMonth() + 1)).slice(-2)
  const year = date.getFullYear()

  const formattedDate = day + '/' + month + '/' + year

  return (
    <Container className="newsArticle">
      <ContentBlock textAlign="left" inline>
        <ContentWrapper>
          <div>
            <Title>{title}</Title>

            <div style={{ marginTop: '2rem' }}>{children}</div>
          </div>
          <Footer>
            <FooterLeft>
              <div style={{ marginRight: '0.5rem' }}>
                Geplaatst op: {formattedDate}
              </div>
            </FooterLeft>
          </Footer>
        </ContentWrapper>
      </ContentBlock>
    </Container>
  )
}

NewsArticle.propTypes = {
  title: propTypes.string,
  image: propTypes.string,
  children: propTypes.any,
  footer: propTypes.shape({
    date: propTypes.date,
    editor: propTypes.string,
  }),
}

export default NewsArticle

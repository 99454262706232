import React, { useState } from 'react'
import propTypes from 'prop-types'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-flow: column wrap;
  margin-bottom: 1.8rem;
  label {
    margin-bottom: 0.3rem;
    color: ${(props) => props.theme.color.label};
  }
`

const ErrorMsg = styled.label`
  color: red !important;
  font-size: 1.5rem !important;
`

const FormElement = ({
  children,
  label,
  required,
  validation,
  notShowed,
  name,
}) => {
  const [dirty, setDirty] = useState(false)
  const [onBlur, setOnBlur] = useState(false)
  const [text, setText] = useState('')

  const [errorMessage, setErrorMessage] = useState('')
  const elements = React.Children.toArray(children)
  let element
  if (elements.length >= 1) {
    element = elements[0]
  } else {
    element = elements
  }

  console.log('ELEMENT', element)
  const handleOnBlur = () => {
    if (!onBlur) {
      setOnBlur(true)
    }
  }

  const handleChange = (event) => {
    const value = event.target.value
    let errorMessages = []
    let charactersValid = false

    // set field dirty
    if (!dirty) {
      setDirty(true)
    }

    // check if input is valid
    if (required && value === '') {
      errorMessages = []
    } else if (validation.rules && validation.rules.length !== 0) {
      validation.rules.forEach((rule) => {
        const regex = new RegExp(rule)
        if (!value.match(regex)) {
          errorMessages.push('Het veld is niet correct')
        }
      })
    } else {
      errorMessages = []
    }

    //Check excluded characters
    if (validation.excludes && validation.excludes.length !== 0) {
      validation.excludes.forEach((rule) => {
        const regex = new RegExp(rule)
        if (value.match(regex)) {
          charactersValid = true
        }
      })
    } else {
      charactersValid = true
    }

    // Update if characters are valid
    if (charactersValid) {
      setText(value)
    }

    // apply errorMessage
    if (required && value === '') {
      setErrorMessage('Het veld is verplicht')
    } else if (errorMessages && errorMessages.length !== 0) {
      setErrorMessage(errorMessages[0])
    } else if (text === '' && value !== '') {
      setErrorMessage('')
    } else if (errorMessages && errorMessages.length === 0) {
      setErrorMessage('')
    }
  }

  return (
    <>
      <Container className="form-element">
        <label>
          {required ? label + '*' : label}{' '}
          {notShowed ? '(wordt niet getoond)' : ''}
        </label>
        {React.cloneElement(
          element,
          {
            onChange: handleChange,
            onBlur: handleOnBlur,
            id: name,
            name: name,
          },
          element.props.children
        )}
        <ErrorMsg>{onBlur && dirty && errorMessage}</ErrorMsg>
      </Container>
    </>
  )
}

export default FormElement

FormElement.propTypes = {
  children: propTypes.string.any,
  label: propTypes.string.isRequired,
  required: propTypes.bool,
  notShowed: propTypes.bool,
  name: propTypes.string.isRequired,
  validation: (propTypes.shape = {
    rules: propTypes.array,
    excludes: propTypes.array,
  }),
}

import React from 'react'
import Page from '../Page'
import { ContentBlock, ContentBlockContainer } from '../common'
import Link from '../style-components/Link'
import { Text } from '../style-components'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
const Member = () => {
  return (
    <Page>
      <ContentBlock textAlign="left">
        <h2>Online inschrijven</h2>
        Klik op onderstaande link om het formulier te openen
        <br /> <br />
        <Link
          icon={{ name: faArrowRight }}
          type="buttonTertiary"
          href="https://forms.gle/XY4oL7J4JERaMksv6"
          blank
        >
          Online inschrijven
        </Link>
        <br />
      </ContentBlock>
      <ContentBlock textAlign="left">
        <h2>Lid worden of bekijken of het iets voor jou is?</h2>
        <p>
          Je kan enkele trainingen proberen alvorens een beslissing te nemen.
        </p>
      </ContentBlock>
      <ContentBlock textAlign="left">
        <h2>Contact:</h2>
        <ul>
          <li>
            Jeugd: <a href="tel=011572150">011/572150</a> (Jos Newman -
            jeugdtrainer)
          </li>
          <li>
            Volwassenen: <a href="tel=0472452941">0472/452941</a> (Marc Goris -
            Voorzitter)
          </li>
        </ul>
      </ContentBlock>
      <ContentBlock></ContentBlock>
    </Page>
  )
}

export default Member

import React from 'react'
import styled from 'styled-components'
import propTypes from 'prop-types'
// Custom compontents
import Header from './common/header/Header'
import { Footer } from './common'
import { mediaQuery, screenWidth } from '../utils/mediaQuery'

const PageContainer = styled.div`
  font-family: 'Open Sans', sans-serif;
  position: relative;
  margin-top: 0rem;
  margin-bottom: 15rem;
  ${mediaQuery({
    width: 'xxl',
    style: `
    padding:0 2rem;
    margin-top: 2rem;
    margin-bottom:11rem;

  `,
  })}

  ${mediaQuery({
    width: 'sm',
    style: `
    padding:0 1.5rem;
    margin-top: 0rem;
    margin-bottom:8rem;
  `,
  })}
`

const HomeBlockPreWord = styled.p`
  text-align: center;
  padding-top: 1rem;
  font-size: ${(props) => props.theme.text.large.fontSize} !important;
  line-height: ${(props) => props.theme.text.large.lineHeight} !important;
`
const Page = ({ children, pageTitle, imageUrl }) => (
  <div>
    <Header pageTitle={pageTitle} imageUrl={imageUrl} />

    <PageContainer className="page-container">{children}</PageContainer>
    <Footer />
  </div>
)

Page.propTypes = {
  children: propTypes.node,
  pageTitle: propTypes.string,
  imageUrl: propTypes.string,
}

export default Page

import React from 'react'
import Page from '../Page'
import { ContentBlock, ContentBlockContainer } from '../common'
import Link from '../style-components/Link'
import styled from 'styled-components'
import { mediaQuery, screenWidth } from '../../utils/mediaQuery'

const ContentContainer = styled.div`
  .parent-image {
    max-height: 27rem;
    overflow: hidden;

    ${mediaQuery({
      width: 'xxl',
      style: `
        max-height:80rem;
    `,
    })}
  }

  img {
    max-width: 40rem;
    margin: 0;
  }
`
const Bestuur = () => {
  return (
    <Page
      pageTitle="Bestuur"
      imageUrl="https://www.harmonieviversel.be/uploads/header/cover_harmonie1.jpg1583190402"
    >
      <ContentContainer>
        <ContentBlock textAlign="left">
          <ContentBlockContainer toColumn="lg" marginGrid={'0 1rem'}>
            <ContentBlock imageAlign="left" inline>
              <div className="parent-image">
                <img src="images/bestuur/marc.jpg" alt="Marc Goris" />
              </div>

              <br />
              <ul>
                <li>Marc Goris</li>
                <li> Voorzitter</li>
                <li> Andere functie : Clubkampioenschap </li>
              </ul>
            </ContentBlock>
            <br />
            <br />
            <ContentBlock imageAlign="left" inline>
              <div className="parent-image">
                <img src="images/bestuur/jimmy.jpg" alt="Jimmy Bogaerts" />
              </div>
              <br />
              <ul>
                <li>Jimmy Bogaerts</li>
                <li> Secretaris</li>
                <li> Andere functies: Tornooien / Mailverkeer</li>
              </ul>
            </ContentBlock>
            <br />
            <br />
            <ContentBlock imageAlign="left" inline>
              <div className="parent-image">
                <img src="images/bestuur/wouter.jpg" alt="Wouter Gelade" />
              </div>
              <br />
              <ul>
                <li>Wouter Gelade</li>
                <li> Penningmeester </li>
                <li> Andere functies: Interclubleider</li>
              </ul>
            </ContentBlock>
            <br />
            <br />
          </ContentBlockContainer>
          <br />
          <br />
          <ContentBlockContainer center toColumn="lg" marginGrid={'0 1rem'}>
            <ContentBlock imageAlign="left" inline>
              <div className="parent-image">
                <img src="images/bestuur/ludo.jpg" alt="Ludo Bellinkx" />
              </div>
              <br />
              <ul>
                <li>Ludo Bellinkx </li>
                <li>Redactie</li>
                <li>
                  Andere functies: Smashke / Clubboekje /
                  Kalendervergadering / Clubfeest
                </li>
              </ul>
            </ContentBlock>
            <ContentBlock imageAlign="left" inline>
              <div className="parent-image">
                <img src="images/bestuur/frederik.jpg" alt="Frederik Pluymers" />
              </div>
              <br />
              <ul>
                <li>Frederik Pluymers </li>
                <li> Ondervoorzitter  </li>
                <li> Andere functies: Materiaalmeester / Kascontrole / Ontbijtdag </li>
              </ul>
            </ContentBlock>
            <ContentBlock inline center></ContentBlock>
          </ContentBlockContainer>
          <br />
          <br />
          <ContentBlockContainer
            center
            toColumn="lg"
            marginGrid={'0 1rem'}
          ></ContentBlockContainer>
        </ContentBlock>
        <ContentBlock>
          <h2>Taken buiten het bestuur</h2>
          <ul>
            <li>Jeugdleiding : Jos Newman / Ludo Bellinkx</li>
            <li>
              Website :{' '}
              <a href="http://www.embeweb.com" target="blank">
                Embeweb.com
              </a>{' '}
            </li>
            {/*
                    <li> Trainer : Patrick Elsen</li>
            */}
          </ul>
        </ContentBlock>
      </ContentContainer>
    </Page>
  )
}

export default Bestuur

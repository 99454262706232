import React from 'react'
import propTypes from 'prop-types'
import styled from 'styled-components'

const FormGroupContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  justify-content: space-between;
  > div {
    width: 100%;
    max-width: ${(props) => (props.fullWidth ? '100%' : '37rem')};

    display: flex;
    flex-flow: column;
  }
`

const FormGroup = ({ children, fullWidth }) => {
  return (
    <FormGroupContainer className="form-group" fullWidth={fullWidth}>
      {children}
    </FormGroupContainer>
  )
}

FormGroup.propTypes = {
  children: propTypes.any,
  fullWidth: propTypes.bool,
}

export default FormGroup

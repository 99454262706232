import React from 'react'
import propTypes from 'prop-types'
import styled from 'styled-components'
import { mediaQuery } from '../../utils/mediaQuery'

const Container = styled.div`
  display: flex;
  border-radius: ${(props) => (props.borderRadius ? '5px' : 0)};
  text-align: center;
  position: relative;
  margin: ${(props) => (props.margin ? props.margin : '0 auto')};
  align-items: ${(props) => (props.center ? 'center' : 'space-between')};
  flex-flow: ${(props) => (props.column ? 'column' : 'row')};
  background: ${(props) => props.background};
  padding: ${(props) => props.padding};
  max-width: ${(props) => props.maxWidth};
  justify-content: ${(props) => (props.center ? 'center' : 'space-between')}
      >div,
    >a {
    position: relative;
    width: 45%;
    text-decoration: none;
    margin: ${(props) => (props.marginGrid ? props.marginGrid : '0')};
  }
  box-shadow: ${(props) =>
    props.boxShadow ? 'rgba(0, 0, 0, 20%) 0px 1px 15px' : ''};
  ${(props) =>
    props.toColumn
      ? mediaQuery({
          width: props.toColumn,
          style: `
          flex-flow:column;
  `,
        })
      : ''}
`

const ContainerInlineBlock = styled.div`
  display: flex;
  border-radius: 5px;

  width: 100%;
  flex-flow: ${(props) => (props.column ? 'column' : 'row')};
  background:${(props) => props.background};
  padding:  ${(props) => props.padding};

  justify-content: ${(props) => (props.center ? 'center' : 'flex-start')} 
  align-items: ${(props) => (props.column ? 'center' : 'flex-start')};
  text-align: center;
  max-width: ${(props) => (props.column ? 'auto' : '80rem')}; 
  >div{
    margin: ${(props) => (props.marginGrid ? props.marginGrid : '0')} ;

  }



       
  ${(props) =>
    props.toColumn
      ? mediaQuery({
          width: props.toColumn,
          style: `
          flex-flow:column;
          align-items:center;
          p{
            text-align:center;

          }
  `,
        })
      : ''}
`

const ContentBlockContainer = ({
  children,
  center,
  column,
  margin,
  background,
  padding,
  maxWidth,
  boxShadow,
  toColumn,
  borderRadius,
  marginGrid,
}) =>
  margin ? (
    <Container
      center={center}
      className="contentBlockContainer"
      column={column}
      margin={margin}
      background={background}
      padding={padding}
      maxWidth={maxWidth}
      boxShadow={boxShadow}
      toColumn={toColumn}
      borderRadius={borderRadius}
      marginGrid={marginGrid}
    >
      {children}
    </Container>
  ) : (
    <ContainerInlineBlock
      center={center}
      column={column}
      background={background}
      maxWidth={maxWidth}
      className="contentBlockContainer"
      padding={padding}
      boxShadow={boxShadow}
      toColumn={toColumn}
      borderRadius={borderRadius}
      marginGrid={marginGrid}
    >
      {children}
    </ContainerInlineBlock>
  )

ContentBlockContainer.propTypes = {
  children: propTypes.string,
  background: propTypes.string,
  padding: propTypes.string,
  inlineBlock: propTypes.boolean,
  center: propTypes.boolean,
  column: propTypes.boolean,
  margin: propTypes.string,
  maxWidth: propTypes.string,
  boxShadow: propTypes.boolean,
  toColumn: propTypes.string,
  borderRadius: propTypes.string,
  marginGrid: propTypes.string,
}

ContentBlockContainer.defaultProps = {
  inlineBlock: false,
  center: false,
  column: false,
  maxWidth: '120rem',
  boxShadow: false,
  borderRadius: false,
}

export default ContentBlockContainer

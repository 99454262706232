export default {
  color: {
    white: '#fefefe',
    primary: '#2b8f68',
    secondary: '#1f1f1f',
    tertiary: '#cfcfcf',
    label: '#5f5f5f',
  },
  text: {
    small: {
      fontSize: '1.55rem',
      lineHeight: '2.0rem',
    },
    smaller: {
      fontSize: '1.6rem',
      lineHeight: '2.8.0em',
    },
    normal: {
      fontSize: '1.7rem',
      lineHeight: '3.0rem',
    },
    large: {
      fontSize: '1.9rem',
      lineHeight: '2.8rem',
    },
    largest: {
      fontSize: '2.2rem',
      lineHeight: '2.6rem',
    },
  },
  title: {
    normal: {
      fontSize: '2.8rem',
      lineHeight: '3.1rem',
    },
    smaller: {
      fontSize: '1.8rem',
      lineHeight: '2.1rem',
    },
    small: {
      fontSize: '2.4rem',
      lineHeight: '2.8rem',
    },
    medium: {
      fontSize: '3.8rem',
      lineHeight: '4.5rem',
    },
    large: {
      fontSize: '4.5rem',
      lineHeight: '5.1rem',
    },
    link: {
      fontSize: '2.5rem',
      lineHeight: '2.8rem',
    },
  },
  button: {
    normal: {
      fontSize: '1.72rem',
      lineHeight: '3em',
    },
  },
  nav: {
    fontSize: '2rem',
    lineHeight: '8rem',
  },
  navMobile: {
    fontSize: '1.8rem',
    lineHeight: '6rem',
  },
  navButton: {
    fontSize: '2.6rem',
    lineHeight: '8rem',
  },
  subNav: {
    fontSize: '1.75rem',
    lineHeight: '3.4rem',
  },
  newsFooter: {
    fontSize: '1.15rem',
    lineHeight: '3.1rem',
  },
  buttonShare: {
    fontSize: '1.4rem',
    lineHeight: ' 2.4rem',
  },
}

import React from "react";
import propTypes from "prop-types";
import styled from "styled-components";

const Image = styled.img`
  width: 100%;
  margin: 2rem 0;
`;

const TextImage = ({ src }) => {
  return <Image src={src} />;
};

TextImage.propTypes = {
  src: propTypes.string,
};
export default TextImage;

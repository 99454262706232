import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import {
  faArrowRight,
  faInfoCircle,
  faTableTennis,
} from '@fortawesome/free-solid-svg-icons'

// Custom compontents
import Header from './common/header/Header'
import { ContentBlock, Footer, ContentBlockContainer } from './common'
import { Link, Text } from './style-components'
import News from './modules/news/News'
import Slideshow from './modules/slideshow/slideshow'
import globalTheme from './globalTheme'
import { LinkBlocks, LinkBlock } from './LinkBlocks'
import { mediaQuery, screenWidth } from '../utils/mediaQuery'

const HomepageContainer = styled.div`
  font-family: 'Open Sans', sans-serif;
  ${mediaQuery({
    width: 'xl',
    style: `
    padding:0 2rem;
  `,
  })}
  ${mediaQuery({
    width: 'sm',
    style: `
    padding:0 1.5rem;
  `,
  })}
`

const Title = styled.h2`
  text-align: center;
  width: 100%;
`

const HomeBlockPreWord = styled.p`
  text-align: center;
  font-size: ${(props) => props.theme.text.large.fontSize} !important;
  line-height: ${(props) => props.theme.text.large.lineHeight} !important;
  margin-bottom: 4rem !important;
`

const HomePage = () => {
  const breakPoint = screenWidth.sm

  const [slidesToShow, setSlidesToShow] = useState(
    screen.width < breakPoint ? 1 : 2
  )

  useEffect(() => {
    // slideShow
    const handleResize = () => {
      if (slidesToShow === 1 && screen.width > breakPoint) {
        setSlidesToShow(2)
      }
      if (slidesToShow === 2 && screen.width < breakPoint) {
        setSlidesToShow(1)
      }
    }
    window.addEventListener('resize', handleResize)
    return (_) => {
      window.removeEventListener('resize', handleResize)
    }
  }, [screen.width])

  return (
    <div>
      <Header />

      <Slideshow key={slidesToShow} slidesToShow={slidesToShow} />
      <HomepageContainer>
        <div
          style={{
            position: 'relative',
            zIndex: '1',
          }}
        >
          <ContentBlockContainer
            column
            margin="2rem auto 4rem auto"
            center
            maxWidth="120rem"
            borderRadius
            padding={screen.width < screenWidth.sm ? '6rem 3rem' : '6rem'}
            background={globalTheme.color.primary}
            boxShadow
          >
            <ContentBlock inline center color={globalTheme.color.white}>
              <HomeBlockPreWord>
                TTC Smash is een tafeltennisclub in Heusden, onze trainingen en
                competities gaan door in de{' '}
                <Link to="/contact" large>
                  {' '}
                  sporthal van Heusden.
                </Link>
              </HomeBlockPreWord>
            </ContentBlock>
            <ContentBlockContainer center toColumn="xl">
              <ContentBlock inline center color={globalTheme.color.white}>
                <p>
                  <Text
                    icon={{
                      src: 'images/icons/padel-small.png',
                      size: 'large',
                    }}
                  >
                    Volwassenen trainingen
                  </Text>
                  <br />
                  <Text>Maandag en donderdag</Text>
                  <br />
                  <Text>19u00-21u00</Text>
                </p>
              </ContentBlock>
              <ContentBlock inline center color={globalTheme.color.white}>
                <p>
                  <Text
                    icon={{
                      src: 'images/icons/padel-small.png',
                      size: 'large',
                    }}
                  >
                    Jeugd (begeleide trainingen)
                  </Text>
                  <br />
                  Maandag en donderdag
                  <br />
                  18u30-20u00
                </p>
              </ContentBlock>
            </ContentBlockContainer>
            <ContentBlock inline center>
              <br />

              <Link
                icon={{ name: faArrowRight }}
                hoverReversed
                type="buttonSecondary"
                to="/nuttige-informatie"
              >
                Meer informatie
              </Link>
            </ContentBlock>
          </ContentBlockContainer>
        </div>

        <ContentBlock inline>
          {/*
        <h2>Start seizoen : 2021-2022</h2>
        <p>
          Harmonie E.V. Viversel wordt gedirigeerd door Willem-Jan Hendrickx. De
          harmonie bestaat uit blaasinstrumenten en slagwerk :{" "}
          <Link to="/over-harmonie-viversel">meer informatie vind je hier</Link>
          .
        </p>
        <p>
          Onze harmonie heeft ook een jeugdorkest, gedirigeerd door Marlon
          Knuts. Wil je graag je instrument opfrissen? Heb je net 1 jaar
          instrumentles en minstens 1 jaar notenleer achter de rug? Dan is
          Jeugdorkest Viversel iets voor jou! Het is de ideale plaats om je
          talenten verder te laten ontwikkelen.
        </p>
       */}
        </ContentBlock>
        <News showAllArticles={false} />

        {/* slideshow : https://react-slideshow.herokuapp.com/ */}
        <LinkBlocks width="120rem">
          <LinkBlock
            to="/nuttige-informatie"
            color="#fefefe"
            bg={globalTheme.color.primary}
          >
            Informatie training
          </LinkBlock>
          <LinkBlock
            to="/contact"
            color="#fefefe"
            bg={globalTheme.color.primary}
          >
            Contact / Adres
          </LinkBlock>
          <LinkBlock
            to="/bestuur"
            color="#fefefe"
            bg={globalTheme.color.primary}
          >
            Bestuur
          </LinkBlock>

          <LinkBlock
            to="/linken"
            color="#fefefe"
            bg={globalTheme.color.primary}
          >
            Interessante links
          </LinkBlock>
        </LinkBlocks>
      </HomepageContainer>

      <Footer />
    </div>
  )
}
export default HomePage

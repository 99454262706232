import React, { useEffect, useState } from 'react'
import Page from '../Page'
import Pdf from '../common/Pdf'
import { ContentBlock } from '../common'

const ClubBoekjes = () => {
  const [pdfs, setPdfs] = useState([])
  useEffect(() => {
    const pdfLoopStart = 2024
    const pdfLoopEnd = 2024
    const exclude = 0

    let generatePdfs = []
    for (let i = pdfLoopEnd; i >= pdfLoopStart; i--) {
      if (i != exclude) {
        generatePdfs.push({
          url: `https://www.ttcsmash.be/pdf/clubboekje${i}.pdf`,
          title: `Clubboekje ${i}-${i + 1}`,
        })
      }
    }
    setPdfs(generatePdfs)
  }, [])

  return (
    <Page pageTitle="Clubboekjes">
      <ContentBlock>
        <Pdf pdfs={pdfs} key={pdfs} />
      </ContentBlock>
    </Page>
  )
}

export default ClubBoekjes

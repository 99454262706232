import React from 'react'
import { ContentBlock } from '../common'
import Page from '../Page'

const History = () => {
  return (
    <Page pageTitle="Geschiedenis">
      <ContentBlock>Hier komt de geschiedenis van de club</ContentBlock>
    </Page>
  )
}

export default History

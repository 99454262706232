import React, { useState, useEffect } from 'react'
import propTypes from 'prop-types'
import FormELement from './FormElement'
import styled from 'styled-components'

const Dropdown = ({
  label,
  required,
  validation,
  notShowed,
  children,
  ...props
}) => {
  const DropdownElem = styled.select`
    width: 100%;
    font-size: 1.5rem !important;
    height: 4rem;
    padding: 0.5rem;
  `
  return (
    <FormELement
      label={label}
      validation={validation}
      required={required}
      notShowed={notShowed}
      {...props}
    >
      <DropdownElem rows="4" cols="100">
        {children}
      </DropdownElem>
    </FormELement>
  )
}

export default Dropdown

Dropdown.propTypes = {
  label: propTypes.string.isRequired,
  required: propTypes.bool,
  notShowed: propTypes.bool,
  children: propTypes.any,
  validation: (propTypes.shape = {
    rules: propTypes.array,
    excludes: propTypes.array,
  }),
}

Dropdown.defaultProps = {
  validation: {
    rules: [],
    excludes: [],
  },
  required: false,
  notShowed: false,
}

import React, { useEffect, Fragment } from 'react'
import { withRouter } from '../WithRouter'
import propTypes from 'prop-types'
const ScrollToTop = ({ history, children }) => {
  useEffect(() => {
    const unlisten = () => {
      document.documentElement.style.scrollBehavior = 'auto'
      setTimeout(() => window.scrollTo(0, 0), 80)
      setTimeout(
        () => (document.documentElement.style.scrollBehavior = 'smooth'),
        5
      )
      // window.scrollTo(0, 0);
    }
    return () => {
      unlisten()
    }
  }, [])

  return <Fragment>{children}</Fragment>
}

ScrollToTop.propTypes = {
  history: propTypes.any,
  children: propTypes.any,
}
export default withRouter(ScrollToTop)

import React, { useState, useEffect, useRef } from 'react'
import { Link as NavLink, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import {
  faEnvelope,
  faBars,
  faAngleDown,
  faTableTennis,
  faCrosshairs,
} from '@fortawesome/free-solid-svg-icons'
import { Link, Text } from '../../style-components'
import { ScrollToTop } from '.'
import { mediaQuery, screenWidth } from '../../../utils/mediaQuery'
import globalTheme from '../../globalTheme'
import CustomLink from './CustomLink'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1f1f1f;
  z-index: 99;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 20px;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 8rem;
  transition: all 0.3s ease;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 3px 15px;

  position: ${(props) => (props.fixedNav ? 'fixed' : 'static')};
  border-top: ${(props) => (props.fixedNav ? '2px solid #5f5f5f' : 'auto')};
  img {
    top: ${(props) => (props.fixedNav ? '-0.2rem !important' : 'auto')};
    max-height: ${(props) => (props.fixedNav ? '5.5rem' : 'auto')};
  }
  a {
    line-height: ${(props) => (props.fixedNav ? '7rem' : 'auto')};
  }

  ${mediaQuery({
    width: 'xxl',
    style: `
    justify-content:space-between;
    align-items: flex-start;
    height:8rem;
    top:0 !important

  `,
  })}
  height: ${(props) => (props.fixedNav ? '7.5rem !important' : 'auto')};

  &#navFixed {
    .navSub {
      top: 70px;
    }
    ${mediaQuery({
      width: 'xxl',
      style: `
      .navSub {
        top: 0;
      }
    
  `,
    })}
    .
  }
`

const HeaderLogo = styled.img`
  opacity: 1;
  position: absolute;
  max-height: 6rem;
  left: 0;
  top: 0rem !important;
  margin: 1rem;
  border: 1px solid #1f1f1f;
  ${mediaQuery({
    width: 'xxl',
    style: `
    top:0 !important;
    position:relative;
    max-height: 6rem;
    margin-left:0;

  `,
  })}
`

const MenuMobileButton = styled.button`
  background: none;
  color: #fefefe;
  border: none;
  font-size: ${(props) => props.theme.navButton.fontSize};
  line-height: ${(props) => props.theme.nav.lineHeight};

  display: none;
  text-align: right;
  padding: 0;
  margin-right: 1rem;

  ${mediaQuery({
    width: 'xxl',
    style: `
    display:block;
    font-size: ${(props) => props.theme.navButton.fontSize};
    line-height:3.8rem;
  
`,
  })}
`
const MenuMobileCloseButton = styled.button`
  background: none;
  color: #fefefe;
  border: none;
  font-size: ${(props) => props.theme.text.largest.fontSize};
  line-height: ${(props) => props.theme.nav.lineHeight};

  display: none;
  text-align: right;
  padding: 0;
  margin-right: 1rem;
  position: relative;
  z-index: 999;

  ${mediaQuery({
    width: 'xxl',
    style: `
    font-size: ${(props) => props.theme.text.largest.fontSize};
    line-height:3.8rem;
  
`,
  })}
`

const NavParent = styled.div`
  display: flex;
  flex-flow: column wrap;
  .navSub a {
    &.active {
      background-color: ${(props) => props.theme.color.primary};
    }
    &:before {
      display: none;
    }
  }
  &:hover {
    .navSub {
      display: block;
    }
  }

  ${mediaQuery({
    overflow: 'hidden',
    width: 'xxl',
    style: `
    top:1rem;
    span span{
      display:none;
    }
  `,
  })}
`
const NavSub = styled.div`
  z-index: 999;
  display: flex;
  flex-flow: column;
  align-items: center;
  position: absolute;
  top: 80px;
  height: 160px;
  display: none;
  . {
    background: ${(props) => props.theme.color.primary};
    &:before {
      display: none;
    }
  }
  ${mediaQuery({
    overflow: 'hidden',
    width: 'xxl',
    style: `
    display:block;
    position: relative;
    height: auto;
    top:0;

  `,
  })}
  a {
    background: ${(props) => props.theme.color.secondary};
    width: 100%;
    height: auto !important;
    margin-right: 0rem !important;
  }

  ${mediaQuery({
    overflow: 'hidden',
    width: 'xxl',
    style: `
    position:relative;
    `,
  })}
`

const Nav = styled.nav`
  margin-right: 0rem;
  line-height: 0.6rem;
  background: ${(props) => props.theme.color.secondary};
  position: relative;
  display: flex;
  z-index: 99;

  ${mediaQuery({
    overflow: 'hidden',
    width: 'xxl',
    style: `
    position:fixed;
    width:100%;
    height:100%;
    left: -100px;
    opacity:0;
    padding-top:10rem;
    padding-bottom:4rem;
    justify-content: flex-start;
    flex-flow: column;
    overflow-y:scroll;
    top:0;
    display:none;
`,
  })}
`

const Navigation = () => {
  const [navOpen, setNavOpen] = useState(false)
  const [mobileNav, setMobileNav] = useState(false)

  const [appLoaded, setAppLoaded] = useState(false)
  const [fixedNav, setFixedNav] = useState(false)

  useEffect(() => {
    window.setTimeout(() => {
      setAppLoaded(true)
      if (navRef.current) {
        navRef.current.style.display = 'flex'
      }
    }, 1)
  })

  useEffect(() => {
    setMobileNav(false)
    setMobileNav(window.innerWidth < screenWidth.xxl)

    const handleResize = () => {
      setMobileNav(window.innerWidth < screenWidth.xxl)
    }
    window.addEventListener('resize', handleResize)

    return (_) => {
      window.removeEventListener('resize', handleResize)
    }
  }, [window.innerWidth])

  const updateHeaderOnScrol = (scrollY) => {
    const rect = headerContainer.current.getBoundingClientRect()
    const height = rect.height
    const top = rect.top

    if (scrollY < top) {
      setFixedNav(false)
      headerContainer.current.style.top = '0'
    } else if (scrollY > top * 2) {
      headerContainer.current.height = rect.height
      headerContainer.current.style.marginBottom = rect.top + 'px'
      headerContainer.current.style.top = '0px'
      headerContainer.current.id = 'navFixed'

      setFixedNav(true)
    } else {
      headerContainer.current.style.top = '-100px'
      headerContainer.current.id = ''

      setFixedNav(false)
    }
  }

  useEffect(() => {
    function updatePosition() {
      updateHeaderOnScrol(window.scrollY)
    }

    window.addEventListener('scroll', updatePosition)
    updatePosition()

    return () => window.removeEventListener('scroll', updatePosition)
  }, [])

  const headerContainer = useRef()
  const navRef = useRef()
  const history = useNavigate()

  return (
    <Container
      ref={headerContainer}
      globalTheme={globalTheme}
      fixedNav={fixedNav}
    >
      {
        <>
          <ScrollToTop />

          <Link to="/" type="noHover">
            <HeaderLogo src="https://www.ttcsmash.be/public/images/logo_ttcsmash_heusden.jpg" />
          </Link>
          <div>
            <MenuMobileButton
              onClick={() => setNavOpen(true)}
              style={{
                display: navOpen ? 'none' : mobileNav ? 'block' : 'none',
              }}
            >
              <Text
                style={{ fontSize: globalTheme.title.medium.fontSize }}
                icon={{ name: faBars }}
              />
            </MenuMobileButton>
            <MenuMobileCloseButton
              style={{
                position: 'absolute',
                top: '0.3rem',
                right: '0.3rem',
                display: navOpen ? (mobileNav ? 'block' : 'none') : 'none',
              }}
              onClick={() => setNavOpen(false)}
            >
              <p
                style={{
                  fontSize: globalTheme.title.medium.fontSize,
                  marginTop: '1rem',
                }}
              >
                X
              </p>
            </MenuMobileCloseButton>
            <Nav
              ref={navRef}
              style={{
                opacity: navOpen ? '1' : mobileNav ? '1' : '1',
                left: navOpen ? '0' : mobileNav ? '100%' : '0',
                transition: appLoaded ? 'left 0.3s' : 0,
              }}
              onClick={() => setNavOpen(!navOpen)}
            >
              <CustomLink to="/" exact className="btn btn-primary btn-lg">
                Home
              </CustomLink>
              <CustomLink to="/nieuws" className=" btn btn-primary btn-lg ">
                Nieuws
              </CustomLink>

              <NavParent className="navParent">
                <CustomLink
                  to="/nuttige-informatie"
                  className=" btn btn-primary btn-lg"
                >
                  <Text icon={{ name: faAngleDown, position: 'reversed' }}>
                    Meer informatie
                  </Text>
                </CustomLink>
                <NavSub className="navSub">
                  <CustomLink to="/album" className="btn btn-primary btn-lg">
                    Fotoalbum
                  </CustomLink>
                  {/*
                  <CustomLink
                    to="/geschiedenis"
                  className={(navData) => (navData.isActive ? ' btn btn-primary btn-lg' : 'btn btn-primary btn-lg')}
                >
                  Geschiedenis
                </CustomLink>
                */}

                  <CustomLink to="/events" className="btn btn-primary btn-lg">
                    Events
                  </CustomLink>
                  <CustomLink to="/smashkes" className="btn btn-primary btn-lg">
                    Smashkes
                  </CustomLink>
                  <CustomLink
                    to="/clubBoekjes"
                    className="btn btn-primary btn-lg"
                  >
                    Clubboekjes
                  </CustomLink>
                </NavSub>
              </NavParent>
              <CustomLink to="/linken" className=" btn btn-primary btn-lg">
                Interessante links
              </CustomLink>
              <CustomLink to="/lid-worden" className=" btn btn-primary btn-lg">
                <Text icon={{ src: 'images/icons/padel-small.png' }}>
                  Lid worden
                </Text>
              </CustomLink>

              <CustomLink to="/bestuur" className=" btn btn-primary btn-lg">
                Bestuur
              </CustomLink>

              <CustomLink to="/contact" className=" btn btn-primary btn-lg">
                <Text icon={{ name: faEnvelope, size: 'large' }}>Contact </Text>
              </CustomLink>
            </Nav>
          </div>
        </>
      }
    </Container>
  )
}

export default Navigation

import React, { useState } from 'react'
import propTypes from 'prop-types'
import { Input } from '../form-elements'

const Text = ({ label, ...props }) => {
  return (
    <Input
      label={label}
      {...props}
      validation={{ excludes: ['^[a-zA-Z ]*$'] }}
    />
  )
}

export default Text

Text.propTypes = {
  label: propTypes.string.isRequired,
  props: propTypes.any,
}

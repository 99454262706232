import React from 'react'
import styled from 'styled-components'
import propTypes from 'prop-types'

const DefaultContainer = styled.div`
  display: flex;
  flex-flow: ${(props) => (props.row ? 'row wrap' : 'column')};;
  justify-content:  ${(props) => (props.row ? 'center' : 'flex-start')};;
  position:relative;
  z-index:1;
  width:100%;
  h1 {
    color: #787777;
    font-size: ${(props) => props.theme.title.normal.fontSize};
    line-height: ${(props) => props.theme.title.normal.lineHeight};

    font-weight: 500;
    margin: 0 0 2.6rem;
  }
  h2 {
    color: #238760;
    font-size: ${(props) => props.theme.title.normal.fontSize};
    line-height: ${(props) => props.theme.title.normal.lineHeight};
    font-weight: 500;
    margin: 0 0 2.6rem;
  }
  h3 {
    color: #238760;
    font-size: ${(props) => props.theme.title.small.fontSize};
    line-height: ${(props) => props.theme.title.small.lineHeight};
    margin: 0 0 2.6rem;  
  }
  p,label,a,strong,ul,ol,li,input,textArea{
    font-size: ${(props) => props.theme.text.normal.fontSize}; 


  }
  p,label,a,strong,ul,ol,li,input,textArea,h2{
    color: ${(props) =>
      props.background
        ? props.theme.color.white
        : props.color
        ? props.color
        : 'auto'} !important;
  }
  select{
    font-size: ${(props) => props.theme.text.normal.fontSize}; 
  }
  p,
  label {
    margin-bottom: 2rem;
    color: #1f1f1f;
} 
 
  }
  a {
   transition: opacity 0.5s; 
   text-decoration:underline;
	 color: ${(props) => (props.background ? props.theme.color.white : '#367ca0')};


  }
  hr{
    border-top: 1px solid #e5e5e5;
    opacity:1;
    margin:1.5rem 0 ;
  }
  b,strong{ 
    color: #787878; 
  } 
  em{
    font-style:italic;
  }
  ul,ol{
    list-style:none;
  } 
  ul.list-extra, ol.list-extra{
      display:flex;
      justify-content:center;
      flex-flow:column;
      text-align:center;

      list-style: none;
      margin-bottom:6rem;
      margin-top:6rem;
  }
  *{

    text-align: ${(props) => props.textAlign};

  }
  .button-default{
    margin-top:2.5rem;
  }
  img{
    text-align: center;
    margin: 0 auto;
    position: relative;
    left:  ${(props) => (props.imageAlign === 'left' ? '0' : '50%')};
    transform:  ${(props) =>
      props.imageAlign === 'left' ? 'none' : 'translate(-50%);'};
    width:100%;
  }
  .altText{
    font-style: italic;
    text-align:center;
    margin-top:0.3rem;
  }

  background: ${(props) => props.background};
  color: ${(props) =>
    props.background
      ? props.theme.color.white
      : props.color
      ? props.color
      : 'auto'};

  max-width: ${(props) => (props.fullWidth ? '100%' : '80rem')};
  margin: ${(props) => (props.inline ? '0 auto' : '2rem auto 4rem auto')};
  width:  ${(props) => (props.fullWidth ? '100%' : '')};
  paddingRight: ${(props) => (props.padding == 'right' ? '2rem' : 0)};
  align-items:  ${(props) => (props.center ? 'center' : 'flex-start')};

`

const ContentBlock = ({
  children,
  background,
  textAlign,
  inline,
  fullWidth,
  padding,
  color,
  center,
  row,
  imageAlign,
}) => {
  return (
    <DefaultContainer
      className="defaultContainer"
      textAlign={textAlign}
      inline={inline}
      fullWidth={fullWidth}
      padding={padding}
      background={background}
      color={color}
      center={center}
      row={row}
      imageAlign={imageAlign}
    >
      {children}
    </DefaultContainer>
  )
}

ContentBlock.propTypes = {
  children: propTypes.any,
  textAlign: propTypes.oneOf(['left', 'center', 'right']),
  size: propTypes.oneOf(['small', 'large']),
  inline: propTypes.boolean,
  background: propTypes.string,
  fullWidth: propTypes.boolean,
  to: propTypes.boolean,
  onClick: propTypes.func,
  padding: propTypes.oneOf(['left', 'right']),
  center: propTypes.boolean,
  color: propTypes.string,
  row: propTypes.string,
  imageAlign: propTypes.oneOf(['left', 'center']),
}

ContentBlock.defaultProps = {
  textAlign: 'left',
  fullWidth: false,
  center: false,
  size: 'small',
}

export default ContentBlock

import React from 'react'
import Page from '../Page'
import { ContentBlock, ContentBlockContainer } from '../common'
import Link from '../style-components/Link'
import { Text } from '../style-components'

const Info = () => {
  return (
    <Page pageTitle="Nuttige informatie">
      <ContentBlock textAlign="left">
        <h2> Adres sporthal</h2>
        <ul>
          <li>
            Ons lokaal ( Sporthal Olympia ) is gelegen in de Pastoor van
            Mierlolaan in Heusden-Zolder.
          </li>
        </ul>
      </ContentBlock>
      <ContentBlock textAlign="left">
        <h2>Trainingen maandag (Zaal 1)</h2>
        <ul>
          <li>
            18u30 tot 20u00 jongeren en beginners : begeleiding door Jos en/of
            Ludo
          </li>
          <li>19u00 tot 21u00 seniores en gevorderden : vrije training</li>
        </ul>
      </ContentBlock>
      <ContentBlock textAlign="left">
        <h2>Trainingen donderdag (Zaal 2)</h2>
        <ul>
          <li>
            18u30 tot 20u00 jongeren en beginners : begeleiding door Jos en/of
            Ludo
          </li>
          <li>19u00 tot 21u00 seniores en gevorderden : vrije training</li>
        </ul>
      </ContentBlock>
      <ContentBlock>
        <img
          src="images/slide-images/ttcsmash-image2.jpg"
          alt="Sporthal Heusden"
        />
      </ContentBlock>

      <ContentBlock textAlign="left">
        <h2>Contact:</h2>
        <ul>
          <li>
            Jeugd: <a href="tel=011572150">011/572150</a> (Jos Newman -
            jeugdtrainer)
          </li>
          <li>
            Volwassenen: <a href="tel=011428389">011/428389</a> (Marc Goris -
            Voorzitter)
          </li>
        </ul>
      </ContentBlock>
      <ContentBlock></ContentBlock>
    </Page>
  )
}

export default Info

import React from 'react'
import propTypes from 'prop-types'
import { Input } from './'

const Date = ({ label, ...props }) => {
  return <Input {...props} type="date" label={label} />
}

export default Date

Date.propTypes = {
  label: propTypes.string.isRequired,
  props: propTypes.any,
}

Date.defaultProps = {
  label: 'datum',
}

const screenWidth = {
  xm: 320,
  sm: 460,
  md: 640,
  lg: 768,
  xl: 920,
  xxl: 1200,
}

const mediaQuery = ({ width, style }) => {
  return `@media screen and (max-width: ${screenWidth[width]}px) {
        ${style}
        }`
}

export { mediaQuery, screenWidth }

import React, { useEffect, useState } from 'react'
import propTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { Link as RouterLink } from 'react-router-dom'
import Text from './Text'
import globalTheme from '../globalTheme'
/*
  color: ${(props) => (props.color == "light" ? "#555" : "#efefef")};
  border: ${(props) =>
    props.color == "default"
      ? "1px solid rgba(0, 0, 0, 0.2); "
      : "1px solid rgba(255, 255, 255, 0.2);"};
        
*/
const LinkButton = styled(RouterLink)`
  border-radius: 0.3rem;
  text-decoration: none;
  &:hover {
    cursor: pointer;
  }
  font-size: ${(props) => props.theme.button.normal.fontSize} !important;
  line-height: ${(props) => props.theme.button.normal.lineHeight} !important;
  color: ${(props) =>
    props.hoverReversed === true ? '#efefef' : props.theme.color.primary};
  &:hover {
    opacity: 0.8;
  }
  ${(props) =>
    props.hoverReversed &&
    css`
      &:hover {
        opacity: 0.8;
        color: #fefefe;
      }
    `}
`

const NoLinkButton = styled.span`
  border-radius: 0.3rem;
  text-decoration: none;
  &:hover {
    cursor: pointer;
  }
  font-size: ${(props) => props.theme.button.normal.fontSize};
  line-height: ${(props) => props.theme.button.normal.lineHeight};
`
const LinkText = styled(RouterLink)`
  font-size: ${(props) => props.theme.button.normal.fontSize};
  line-height: ${(props) => props.theme.button.normal.lineHeight};
  text-decoration: none;
  color: ${(props) =>
    props.hoverReversed === true ? '#fefefe' : props.theme.color.primary};
  border-radius: 3px;
  margin: 0 0.8rem;
  &:hover {
    opacity: 0.8;
  }
  ${(props) =>
    props.hoverReversed &&
    css`
      &:hover {
        color: #fefefe;
      }
    `}
`
const LinkTextExternal = styled.a`
  text-decoration: none;
  color: ${(props) =>
    props.hoverReversed === true ? '#efefef' : props.theme.color.primary};
  border-radius: 3px;
  margin-right: 0.8rem;
  &:hover {
    opacity: 0.8;
  }
  ${(props) =>
    props.hoverReversed &&
    css`
      &:hover {
        color: #fefefe;
      }
    `}
`

const LinkDefault = styled(RouterLink)`
  text-decoration: none;
  color: ${(props) => (props.hoverReversed === true ? '#bfbfbf' : '#1f1f1f')};
  border-radius: 3px;
  //border-bottom: 1px solid #367ca0;
  font-size: ${(props) =>
    props.large ? props.theme.text.large.fontSize : '1.7rem'} !important;
  line-height: 3rem
  &:hover {
    opacity: 0.8;
  }
  ${(props) =>
    props.hoverReversed &&
    css`
      &:hover {
        opacity: 0.8;
        color: #fefefe;
      }
    `}
`

const LinkButtonExternal = styled.a`
  border-radius: 0.3rem;
  text-decoration: none;
  &:hover {
    cursor: pointer;
  }
  font-size: ${(props) => props.theme.button.normal.fontSize} !important;
  line-height: ${(props) => props.theme.button.normal.lineHeight} !important;
`

const LinkDefaultNoHover = styled(RouterLink)`
  text-decoration: none;
  color: #1f1f1f;
  border-radius: 3px;
  //border-bottom: 1px solid #367ca0;
  margin: 0 0.8rem;
  &:hover {
    opacity: 1;
  }
`

const LinkButtonShare = styled.div`
  text-decoration: none;
  color: #fefefe;
  border-radius: 3px;
  font-size: ${(props) => props.theme.buttonShare.fontSize};
  line-height: ${(props) => props.theme.buttonShare.lineHeight};
  background: #2979a2 !important;
  padding: 0rem 1rem;
  &:hover {
    opacity: 0.8;
  }
`

const extraHoverProps = (hoverReversed) => {
  return {
    color: hoverReversed ? '#238760' : '#fefefe',
    background: hoverReversed ? '#fefefe' : '#238760',
    border: 'none',
    margin: '1px',
  }
}

const buttonDefault = ({ small, fullWidth, hover, hoverReversed }) => {
  let extraProps
  if (hover) {
    extraProps = extraHoverProps(hoverReversed)
  }

  return {
    padding: '0 1.8rem',
    display: fullWidth ? 'block' : 'inline-block',
    color: '#4f4f4f',
    background: '#fefefe',
    border: '1px solid  #d2d2d2',
    ...extraProps,
  }
}

const buttonPrimary = ({ small, fullWidth, hover, hoverReversed }) => {
  let extraProps
  if (hover) {
    extraProps = extraHoverProps(hoverReversed)
  }
  return {
    padding: '0 1.8rem',
    lineHeight: small ? '2.8rem' : '3rem',
    fontSize: small ? '1.55rem' : '1.7rem',
    display: fullWidth ? 'block' : 'inline-block',
    color: '#fefefe',
    background: 'none',
    border: '1px solid  #fefefe',
    ...extraProps,
  }
}

const buttonSecondary = ({ hover, hoverReversed }) => {
  let extraProps
  if (hover) {
    extraProps = extraHoverProps(hoverReversed)
  }
  return {
    padding: '0 1.8rem',
    display: 'inline-block',
    color: '#fefefe',
    background: 'none',
    border: '1px solid  #fefefe',
    textAlign: 'center',
    ...extraProps,
  }
}

const buttonTertiary = ({ hover, hoverReversed }) => {
  let extraProps
  if (hover) {
    extraProps = extraHoverProps(hoverReversed)
  }
  return {
    padding: '0 1.8rem',
    display: 'inline-block',
    color: '#fefefe',
    border: '1px solid  #fefefe',
    background: globalTheme.color.primary,
    textAlign: 'center',
    textDecoration: 'none',
    ...extraProps,
  }
}

const Content = ({
  hover,
  children,
  icon,
  type,
  to,
  fullWidth,
  small,
  large,
  hoverReversed,
  noLink,
  href,
  blank,
}) => {
  if (type == 'noHover') {
    return <LinkDefaultNoHover to={to}>{children}</LinkDefaultNoHover>
  }
  if (type == 'buttonShare') {
    return (
      <>
        <RouterLink to={to}>
          <LinkButtonShare>
            {children}
            <Text
              icon={{ name: icon.name, size: 'large', color: 'light' }}
              reversed
            ></Text>
          </LinkButtonShare>
        </RouterLink>
      </>
    )
  } else if (type == 'buttonDefault') {
    return (
      <div
        style={{
          display: fullWidth ? 'inline-block' : 'flex',
          justifyContent: fullWidth ? 'none' : 'center',
        }}
        className="button-default"
      >
        {noLink && (
          <NoLinkButton
            to={to}
            style={buttonDefault({ small, fullWidth, hover, hoverReversed })}
          >
            <Text icon={icon}>{children}</Text>
          </NoLinkButton>
        )}
        {!noLink && (
          <LinkButton
            to={to}
            style={buttonDefault({ small, fullWidth, hover, hoverReversed })}
            className="button-secondary"
          >
            <Text icon={icon}>{children}</Text>
          </LinkButton>
        )}
      </div>
    )
  } else if (type == 'buttonTertiary') {
    if (href !== '') {
      return (
        <div>
          <LinkButtonExternal
            href={href}
            target={blank ? '_blank' : ''}
            style={buttonTertiary({ hover, hoverReversed })}
          >
            <Text icon={icon}>{children}</Text>
          </LinkButtonExternal>
        </div>
      )
    } else {
      return (
        <div>
          <LinkButton to={to} style={buttonTertiary({ hover, hoverReversed })}>
            <Text icon={icon}>{children}</Text>
          </LinkButton>
        </div>
      )
    }
  } else if (type == 'buttonSecondary') {
    return (
      <div>
        <LinkButton to={to} style={buttonSecondary({ hover, hoverReversed })}>
          <Text icon={icon}>{children}</Text>
        </LinkButton>
      </div>
    )
  } else if (type == 'buttonPrimary') {
    return (
      <div
        style={{
          display: fullWidth ? 'inline-block' : 'flex',
          justifyContent: fullWidth ? 'none' : 'center',
        }}
      >
        <LinkButton
          to={to}
          style={buttonPrimary({ small, fullWidth, hover, hoverReversed })}
        >
          <Text icon={icon}>{children}</Text>
        </LinkButton>
      </div>
    )
  } else if (type == 'formButton') {
    return (
      <div
        style={{
          display: fullWidth ? 'inline-block' : 'flex',
          justifyContent: fullWidth ? 'none' : 'flex-end',
          marginTop: '-1rem',
        }}
      >
        <LinkButton
          to={to}
          style={buttonDefault({ small, fullWidth, hover, hoverReversed })}
        >
          <Text icon={icon}>{children}</Text>
        </LinkButton>
      </div>
    )
  } else if (type == 'text') {
    if (href !== '') {
      return (
        <LinkTextExternal
          hoverReversed={hoverReversed}
          href={href}
          target={blank ? '_blank' : ''}
        >
          <Text icon={icon}>{children}</Text>
        </LinkTextExternal>
      )
    } else {
      return (
        <LinkText hoverReversed={hoverReversed} to={to}>
          <Text icon={icon}>{children}</Text>
        </LinkText>
      )
    }
  } else if (icon)
    return (
      <LinkButton
        hoverReversed={hoverReversed}
        style={{ marginLeft: '1rem' }}
        to={to}
      >
        <Text icon={icon}>{children}</Text>
      </LinkButton>
    )
  else
    return (
      <LinkDefault hoverReversed={hoverReversed} large={large} to={to}>
        {children}
      </LinkDefault>
    )
}

const Link = (props) => {
  const [hover, setHover] = useState(false)
  if (props.type == 'text') {
    return <Content {...props} hover={hover}></Content>
  }
  if (props.type == 'default') {
    return <Content {...props} hover={hover}></Content>
  } else {
    console.log(props.type)
    return (
      <div
        style={{
          display: props.type == 'buttonSecondary' ? 'inline-block' : 'block',
        }}
        onMouseEnter={() => {
          setHover(true)
        }}
        onMouseLeave={() => {
          setHover(false)
        }}
      >
        <Content {...props} hover={hover}></Content>
      </div>
    )
  }
}

Content.propTypes = {
  type: propTypes.oneOf([
    'text',
    'default',
    'buttonDefault',
    'buttonPrimary',
    'buttonSecondary',
    'buttonTertiary',
    'buttonShare',
    'formButton',
    'noHover',
  ]),
  children: propTypes.string,
  to: propTypes.string,
  fullWidth: propTypes.boolean,
  icon: propTypes.any,
  small: propTypes.boolean,
  large: propTypes.boolean,
  hover: propTypes.boolean,
  hoverReversed: propTypes.boolean,
  noLink: propTypes.boolean,
  href: propTypes.string,
  blank: propTypes.string,
}

Content.defaultProps = {
  fullWidth: false,
  hoverReversed: false,
  blank: false,
}

Link.propTypes = {
  type: propTypes.any,
}

Link.defaultProps = {
  type: 'default',
}
export default Link

import React, { useState, useEffect } from 'react'
import propTypes from 'prop-types'
import styled from 'styled-components'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

import { Link } from '../../style-components'
import { mediaQuery } from '../../../utils/mediaQuery'
import decodeHTMLEntities from '../../../utils/decodeHtmlEntities'
import SingleAlbum from '../../common/SingleAlbum'
import EventArticle from './EventArticle'

const NewsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  //margin-bottom: 6.5rem;
  padding-top: 2rem;

  ${mediaQuery({
    width: 'xxl',
    style: `

    margin-top: 0rem;
    padding-top:0;
  `,
  })}

  ${mediaQuery({
    width: 'sm',
    style: `
    margin-top: 2rem;
    padding-top:0rem;
  `,
  })}
  flex-flow: row wrap;
  align-items: stretch;
  .eventArticle {
    margin-bottom: 6rem;
    width: 100%;
  }
`

const Container = styled.div`
  max-width: 110rem;
  margin: 0 auto 16rem auto;
`

/*
const newsArticles = [
  {
    id: 1,
    title: "Opendeur repetitie",
    image:
      "https://www.harmonieviversel.be/uploads/thumbs/open-repetitie.jpg1631794699",
    date: "16/09/2021",
    editor: "Geschreven door de werkgroep",
    content: `<p>
    Speel jij een instrument? En heb je zin om in een fijne groep van
    jong en oud samen muziek te maken?
  </p>
  <p>
    Kom onze harmonie dan eens verkennen, op onze opendeur-repetitie op
    3 oktober!
  </p>
  <p>
    Heb je interesse, geef dan gerust een
    <a href="/contact">seintje</a>, dan zetten we de muziek en een
    drankje alvast klaar!
  </p>
  <p>Tot dan!</p>`,
  },
  {
    id: 2,
    title: "thuisgeleverd ontbijt",
    image:
      "https://www.harmonieviversel.be/uploads/thumbs/241136566_4402596979776691_5741195857440126928_n.jpg1630599173",
    date: "16/09/2021",
    editor: "Geschreven door de werkgroep",
    content: `    <strong>
    Na een geslaagde eerste editie vorig jaar, zijn we weer klaar om
    jullie te verwennen met een thuisgeleverd ontbijt!
  </strong>
  <p>
    <strong>Datum </strong>20 oktober 2021
  </p>
  <strong>Aflevermomenten:</strong>
  <p>
    tussen 7u en 8u <br />
    tussen 8u en 9u <br />
    tussen 9u en 10u
  </p>
  <p>
    <strong>Bestellen: </strong>
    kan via de leden of overschrijving, met vermelding van aantal
    pakketten, afleveradres, telefoonnummer en tijdslot. Bestel tijdig
    je ontbijtpakketten (voor 3 oktober!)
  </p>
  <p>Smakelijk!</p>`,
  },
  {
    id: 3,
    title: "Opendeur repetitie",
    image:
      "https://www.harmonieviversel.be/uploads/thumbs/open-repetitie.jpg1631794699",
    date: "16/09/2021",
    editor: "Geschreven door de werkgroep",
    content: `<p>
    Speel jij een instrument? En heb je zin om in een fijne groep van
    jong en oud samen muziek te maken?
  </p>
  <p>
    Kom onze harmonie dan eens verkennen, op onze opendeur-repetitie op
    3 oktober!
  </p>
  <p>
    Heb je interesse, geef dan gerust een <a href="/contact">seintje</a>, dan zetten we de muziek en een
    drankje alvast klaar!
  </p>
  <p>Tot dan!</p>`,
  },
  {
    id: 4,
    title: "thuisgeleverd ontbijt",
    image:
      "https://www.harmonieviversel.be/uploads/thumbs/241136566_4402596979776691_5741195857440126928_n.jpg1630599173",
    date: "16/09/2021",
    editor: "Geschreven door de werkgroep",
    content: `    <strong>
    Na een geslaagde eerste editie vorig jaar, zijn we weer klaar om
    jullie te verwennen met een thuisgeleverd ontbijt!
  </strong>
  <p>
    <strong>Datum </strong>20 oktober 2021
  </p>
  <strong>Aflevermomenten:</strong>
  <p>
    tussen 7u en 8u <br />
    tussen 8u en 9u <br />
    tussen 9u en 10u
  </p>
  <p>
    <strong>Bestellen: </strong>
    kan via de leden of overschrijving, met vermelding van aantal
    pakketten, afleveradres, telefoonnummer en tijdslot. Bestel tijdig
    je ontbijtpakketten (voor 3 oktober!)
  </p>
  <p>Smakelijk!</p>`,
  },
  {
    id: 5,
    title: "Opendeur repetitie",
    image:
      "https://www.harmonieviversel.be/uploads/thumbs/open-repetitie.jpg1631794699",
    date: "16/09/2021",
    editor: "Geschreven door de werkgroep",
    content: `<p>
    Speel jij een instrument? En heb je zin om in een fijne groep van
    jong en oud samen muziek te maken?
  </p>
  <p>
    Kom onze harmonie dan eens verkennen, op onze opendeur-repetitie op
    3 oktober!
  </p>
  <p>
    Heb je interesse, geef dan gerust een <a href="/contact">seintje</a>, dan zetten we de muziek en een
    drankje alvast klaar!
  </p>
  <p>Tot dan!</p>`,
  },
  {
    id: 6,
    title: "thuisgeleverd ontbijt",
    image:
      "https://www.harmonieviversel.be/uploads/thumbs/241136566_4402596979776691_5741195857440126928_n.jpg1630599173",
    date: "16/09/2021",
    editor: "Geschreven door de werkgroep",
    content: `    <strong>
    Na een geslaagde eerste editie vorig jaar, zijn we weer klaar om
    jullie te verwennen met een thuisgeleverd ontbijt!
  </strong>
  <p>
    <strong>Datum </strong>20 oktober 2021
  </p>
  <strong>Aflevermomenten:</strong>
  <p>
    tussen 7u en 8u <br />
    tussen 8u en 9u <br />
    tussen 9u en 10u
  </p>
  <p>
    <strong>Bestellen: </strong>
    kan via de leden of overschrijving, met vermelding van aantal
    pakketten, afleveradres, telefoonnummer en tijdslot. Bestel tijdig
    je ontbijtpakketten (voor 3 oktober!)
  </p>
  <p>Smakelijk!</p>`,
  },
  {
    id: 7,
    title: "Opendeur repetitie",
    image:
      "https://www.harmonieviversel.be/uploads/thumbs/open-repetitie.jpg1631794699",
    date: "16/09/2021",
    editor: "Geschreven door de werkgroep",
    content: `<p>
    Speel jij een instrument? En heb je zin om in een fijne groep van
    jong en oud samen muziek te maken?
  </p>
  <p>
    Kom onze harmonie dan eens verkennen, op onze opendeur-repetitie op
    3 oktober!
  </p>
  <p>
    Heb je interesse, geef dan gerust een <a href="/contact">seintje</a>, dan zetten we de muziek en een
    drankje alvast klaar!
  </p>
  <p>Tot dan!</p>`,
  },
  {
    id: 8,
    title: "thuisgeleverd ontbijt",
    image:
      "https://www.harmonieviversel.be/uploads/thumbs/241136566_4402596979776691_5741195857440126928_n.jpg1630599173",
    date: "16/09/2021",
    editor: "Geschreven door de werkgroep",
    content: `    <strong>
    Na een geslaagde eerste editie vorig jaar, zijn we weer klaar om
    jullie te verwennen met een thuisgeleverd ontbijt!
  </strong>
  <p>
    <strong>Datum </strong>20 oktober 2021
  </p>
  <strong>Aflevermomenten:</strong>
  <p>
    tussen 7u en 8u <br />
    tussen 8u en 9u <br />
    tussen 9u en 10u
  </p>
  <p>
    <strong>Bestellen: </strong>
    kan via de leden of overschrijving, met vermelding van aantal
    pakketten, afleveradres, telefoonnummer en tijdslot. Bestel tijdig
    je ontbijtpakketten (voor 3 oktober!)
  </p>
  <p>Smakelijk!</p>`,
  },
];
*/

const News = ({ showAllArticles }) => {
  const [eventArticles, setEventArticles] = useState('')
  const getEventArticles = async () => {
    fetch('https://www.ttcsmash.be/user/api/getAllEventArticles', {
      mode: 'cors',
    })
      .then((response) => response.json())
      .then((data) => {
        let articles = []
        if (!showAllArticles) {
          articles.push(data[0])
          articles.push(data[1])
        } else {
          articles = data
        }
        setEventArticles(
          articles /*.filter((singleData) => singleData.id > 65)*/
        )
        /*
        setNewsArticles([
          {
            id: '58',
            title: 'Clubfeest',
            body: `&lt;p style=&quot;color: #626262;&quot;&gt;Ons clubfeest op zaterdag 21 mei 2022 vond plaats in de omgeving van TODI op het terrein van B-MINE.
            Omdat we dit jaar ons 50-jarig bestaan vierden, werd het iets meer dan een gewone bbq avond. 
            <br>
            Verschillende activiteiten stonden op het programma :  waaruit men mocht kiezen.
            Na de activiteiten konden de leden genieten van een maaltijd bereid op de gril. <br>Na de maaltijd volgde er een quizhalfuurtje  en als afsluiter werden er spelletjes gespeeld en prijzen uitgedeeld.

            
            <br/>

            <a href="/events">Volledig verslag en foto's</a>`,
            image: 'website_harmonie2.jpg1590239516',
            date: '2022-06-07',
            highlighted: '0',
            visible: '1',
            deleted: null,
            moderator: 'Marijn',
            showOnHomepage: '1',
          },
          {
            id: '57',
            title: 'Clubkampioenschap ! ',
            body: `&lt;p style=&quot;color: #626262;&quot;&gt;Het is weer paaszaterdag geweest, dus tijd voor ons clubkampioenschap. <br> Nog een dikke proficiat aan Wouter en Vince en aan iedereen die zich had ingeschreven voor dit
            tornooitje, want hoe meer zielen, hoe meer vreugde. 
            <br/>

            <a href="/events">Volledig verslag en foto's</a>`,
            image: 'website_harmonie2.jpg1590239516',
            date: '2022-05-17',
            highlighted: '0',
            visible: '1',
            deleted: null,
            moderator: 'Marijn',
            showOnHomepage: '1',
          },
          {
            id: '56',
            title: 'Nieuwe website ! ',
            body: '&lt;p style=&quot;color: #626262;&quot;&gt;Onze website was aan vervanging toe, na lange dagen is dit het resultaat! <br> We hopen dat de website in de smaak valt. Heb je een vraag of een opmerking, aarzel dan niet ons te <a href="/contact">contacteren</a>',
            image: 'website_harmonie2.jpg1590239516',
            date: '2022-05-20',
            highlighted: '0',
            visible: '1',
            deleted: null,
            moderator: 'Marijn',
            showOnHomepage: '1',
          },
        ]) */
      })
      .catch((err) => console.log(err))
  }
  useEffect(() => {
    getEventArticles()
  }, [])
  return (
    <Container>
      <NewsWrapper>
        {eventArticles &&
          eventArticles.map((article, index) => {
            return (
              <EventArticle
                key={article.id}
                title={decodeHTMLEntities(article.title)}
                footer={{
                  date: new Date(article.date.replace(' ', 'T')),
                  editor: article.moderator,
                }}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: decodeHTMLEntities(article.body),
                  }}
                ></div>
                {article.image && (
                  <SingleAlbum
                    src={
                      'https://ttcsmash.be/user/uploads/events/' + article.image
                    }
                    thumb={
                      'https://ttcsmash.be/user/uploads/events/thumbs/' +
                      article.image
                    }
                  >
                    <img
                      src={
                        'https://ttcsmash.be/user/uploads/events/thumbs/' +
                        article.image
                      }
                      style={{ width: '100%', marginTop: '1rem' }}
                    />
                  </SingleAlbum>
                )}
              </EventArticle>
            )
          })}
      </NewsWrapper>

      {!showAllArticles && (
        <Link icon={{ name: faSearch }} type="buttonDefault" to="/nieuws">
          Meer Nieuws
        </Link>
      )}
    </Container>
  )
}

News.propTypes = {
  showAllArticles: propTypes.boolean,
}

News.defaultProps = {
  showAllArticles: false,
}

export default News

import React from 'react'
import propTypes from 'prop-types'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
const iconSize = {
  default: '1.5rem',
  large: '1.9rem',
  extraLarge: '3.5rem',
}

const iconColor = {
  default: 'auto',
  light: '#fefefe',
}

const TextIcon = styled.span`
  opacity: 0.8;
  position: relative;
`

const TextIconUp = styled.span`
  opacity: 0.8;
`

const ExtraIconStyle = styled.span`
  padding-right: ${(props) =>
    props.containText ? (props.size == 'extraSmall' ? '0.8rem' : '1rem') : ''};
  font-size: ${(props) =>
    props.size == 'extraSmall' ? '1.5rem' : iconSize[props.size]};
  color: ${(props) =>
    props.size == 'extraLarge' ? 'rgba(5,10,12,.09)' : iconColor[props.color]};
  top: ${(props) =>
    props.position == 'normal'
      ? '-0.2rem'
      : props.size == 'small'
      ? '-0.1rem'
      : '-0.2rem'};
  position: relative;
`

const ExtraImageIconStyle = styled.span`
  padding-right: ${(props) => (props.containText ? '0.8rem' : '')};
  position: relative;
  left: 0;
  top: -0.3rem;
`

const Text = ({ icon, children, reversed }) => {
  const containText = !!children

  if (reversed) {
    return (
      <>
        <TextIcon className="icon reversed">
          <FontAwesomeIcon style={{ color: '#fefefe' }} icon={icon.name} />
        </TextIcon>
        {children}
      </>
    )
  }
  if (icon && icon.position == 'reversed') {
    return (
      <>
        {children}
        <TextIcon
          className="icon"
          style={{
            top: icon.size == 'small' ? '-0.1rem' : '0.2rem',
            left: '1.5rem',
          }}
        >
          <ExtraIconStyle
            containText={containText}
            size={icon.size}
            color={icon.color}
            position={icon.position}
          >
            <FontAwesomeIcon icon={icon.name} />
          </ExtraIconStyle>
        </TextIcon>
      </>
    )
  }
  if (icon && icon.position == 'up') {
    return (
      <>
        <TextIconUp className="icon icon-up">
          <ExtraIconStyle
            containText={containText}
            size={icon.size}
            color={icon.color}
            position={icon.position}
          >
            <FontAwesomeIcon icon={icon.name} />
          </ExtraIconStyle>
        </TextIconUp>
        {children}
      </>
    )
  } else if (icon && reversed) {
    return (
      <>
        {children}
        <TextIcon className="icon icon-reversed" style={{ top: 0 }}>
          <ExtraIconStyle
            containText={containText}
            size={icon.size}
            color={icon.color}
            position={icon.position}
          >
            <FontAwesomeIcon icon={icon.name} />
          </ExtraIconStyle>
        </TextIcon>
      </>
    )
  } else if (icon && icon.src !== undefined) {
    return (
      <>
        <TextIcon
          className="icon"
          style={{
            top: icon.size == 'small' ? '-0.1rem' : '0.2rem',
          }}
        >
          <ExtraImageIconStyle
            containText={containText}
            size={icon.size}
            color={icon.color}
            position={icon.position}
          >
            <img
              src={icon.src}
              alt=""
              style={{
                width: '2.35rem',
                left: 0,
                transform: 'none',
              }}
            />
          </ExtraImageIconStyle>
        </TextIcon>
        {children}
      </>
    )
  } else if (icon) {
    return (
      <>
        <TextIcon
          className="icon"
          style={{
            top: icon.size == 'small' ? '-0.1rem' : '0.2rem',
          }}
        >
          <ExtraIconStyle
            containText={containText}
            size={icon.size}
            color={icon.color}
            position={icon.position}
          >
            <FontAwesomeIcon icon={icon.name} />
          </ExtraIconStyle>
        </TextIcon>
        {children}
      </>
    )
  } else {
    return <>{children}</>
  }
}

Text.propTypes = {
  icon: propTypes.shape({
    name: propTypes.any,
    size: propTypes.oneOf(
      'default',
      'large',
      'extraLarge',
      'small',
      'extraSmall'
    ),
    position: propTypes.oneOf('default', 'up', 'reversed'),
    type: propTypes.oneOf('default', 'border'),
    color: propTypes.oneOf('default', 'light'),
    src: propTypes.string,
  }),
  reversed: propTypes.boolean,
  noStyle: propTypes.booelean,
  children: propTypes.Content,
}

export default Text

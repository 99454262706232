import React, { useState, useEffect } from 'react'
import propTypes from 'prop-types'
import FormELement from './FormElement'
import styled from 'styled-components'

const Input = ({ label, required, validation, notShowed, type, name }) => {
  const InputElem = styled.input`
    font-size: 1.5rem !important;
    height: 4rem;
  `
  return (
    <FormELement
      label={label}
      name={name}
      validation={validation}
      required={required}
      notShowed={notShowed}
    >
      <InputElem type={type} />
    </FormELement>
  )
}

export default Input

Input.propTypes = {
  label: propTypes.string.isRequired,
  name: propTypes.string.isRequired,
  type: propTypes.string,
  required: propTypes.bool,
  validation: (propTypes.shape = {
    rules: propTypes.array,
    excludes: propTypes.array,
  }),
  notShowed: propTypes.bool,
}

Input.defaultProps = {
  validation: {
    rules: [],
    excludes: [],
  },
  type: 'text',
  required: false,
  notShowed: false,
}

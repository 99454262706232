import React from 'react'
import { render } from 'react-dom'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  HashRouter,
  useNavigate,
  Navigate,
} from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css'
import styled from 'styled-components'
import App from './components/App'

const AppStyle = styled.div`
  ul {
    padding-left: 0;
  }
`

render(
  <>
    <Router>
      <AppStyle>
        <App />
      </AppStyle>
    </Router>
  </>,
  document.getElementById('app')
)

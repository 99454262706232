import React from 'react'
import styled from 'styled-components'
import propTypes from 'prop-types'

import { ContentBlock } from '../../common'
import { mediaQuery } from '../../../utils/mediaQuery'

const Title = styled.h2`
  margin-bottom: 2rem;
`

const Container = styled.div`
  height: 100%;
  div {
    white-space: initial !important;
  }
`

const ContentWrapper = styled.div`
  display: flex;
  flex-flow: column wrap;
  height: 100%;
  justify-content: space-between;
  div,
  p {
    font-size: ${(props) => props.theme.text.normal.fontSize} !important;
    line-height: ${(props) => props.theme.text.normal.lineHeight} !important;
  }
  img{
    position:static;
    left:0;
    transform:none;
  }
  .images{
    display:flex;
    justify-content: space-between;
    flex-flow:row wrap;
    ${mediaQuery({
      width: 'lg',
      style: `

    `,
    })}

  }
  .imageWrapper{
    width: 50%;
    height: 25rem;
    padding: 2rem 1rem;
    overflow: hidden;
    
  ${mediaQuery({
    width: 'lg',
    style: `
      width:100%;
      height: 25rem;
      display:flex;
      justify-content:center;
      padding:1rem 0;
  `,
  })}
  }
`

const EventArticle = ({ children, title, images }) => {
  return (
    <Container className="newsArticle">
      <ContentBlock textAlign="left" inline>
        <ContentWrapper>
          <div>
            <Title>{title}</Title>

            <div style={{ marginTop: '2rem' }}>{children}</div>
          </div>
          <br />
          <div className="images">
             
            {images && images.map((image) => {
                return(
                  <>
                           <div className="imageWrapper">
                                    <img style={{ maxWidth: '400px' }} src={image} />

                  </div>
                  </>
         

                )
       
            })}
            <br />
            <br />
          </div>
        </ContentWrapper>
      </ContentBlock>
    </Container>
  )
}

EventArticle.propTypes = {
  title: propTypes.string,
  image: propTypes.string,
  images: propTypes.array,
  children: propTypes.any,
}

export default EventArticle

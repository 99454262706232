import Contact from './Contact'
import Member from './Member'
import Links from './Links'
import Gdpr from './Gdpr'
import Nieuws from './Nieuws'
import Album from './Album'
import Bestuur from './Bestuur'
import History from './History'
import EventPage from './EventPage'
import Info from './Info'
import PdfPage from './PdfPage'
import ClubBoekjes from './ClubBoekjes'

export {
  Contact,
  Links,
  Gdpr,
  Nieuws,
  Album,
  Bestuur,
  Member,
  History,
  EventPage,
  Info,
  PdfPage,
  ClubBoekjes,
}

import React from 'react'
import styled from 'styled-components'
import ContentBlock from './ContentBlock'
import ContentBlockContainer from './ContentBlockContainer'
import { Link, Text } from '../style-components'
import { mediaQuery } from '../../utils/mediaQuery'

const Parent = styled.div`
  display: flex;
  justify-content: center;
  background-color: #1f1f1f;
  ${mediaQuery({
    width: 'xl',
    style: `
    padding:0 2rem;
  `,
  })}
  ${mediaQuery({
    width: 'sm',
    style: `
    padding:0 1.5rem;
  `,
  })}
`

const FooterBottom = styled.div`
  display: flex;
  justify-content: center;
  padding: 2rem;
  align-items: center;
  border-top: 1px solid #5f5f5f;
  width: 100%;
  p,
  a,
  span {
    color: #cfcfcf !important;
  }
`

const Title = styled.h2`
  text-align: center;
  display: flex;
  justify-content: center;
  color: #fefefe;
  margin-bottom: 5rem;
  font-size: ${(props) => props.theme.title.small.fontSize};
  line-height: ${(props) => props.theme.title.small.lineHeight};
`

const Container = styled.div`
  padding: 6rem 0;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`

const LogoContainer = styled.div`
  text-align: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  width: 100%;
  align-items: center;
  padding-bottom: 2rem;

  a {
    width: 20rem;
    display: flex;
    margin-right: 2rem;
    &:last-of-type {
      margin-right: 0;
    }
    margin-bottom: 2rem;

    ${mediaQuery({
      width: 'sm',
      style: `
    width:100%;
    max-width:20rem;
    margin-right:0;
  `,
    })} {
      max-width: 100%;
    }
  }
  img{
    width 100%;
  }
`

const Footer = () => {
  return (
    <>
      <Parent>
        <Container>
          <Title>Met dank aan onze sponsoren:</Title>
          <LogoContainer>
            <a
              href="https://www.mijnspar.be/winkels/spar-heusden"
              target="_blank"
              rel="noreferrer"
            >
              <img
                alt="Spar Aerts"
                src="http://www.ttcsmash.be/public/images/Aerts.jpg"
              />
            </a>
            <a
              href="https://www.brasseriesavarin.be/ "
              target="_blank"
              rel="noreferrer"
            >
              <img
                alt="Savarin Brasserie"
                src="http://www.ttcsmash.be/public/images/Savarin.jpg"
              />
            </a>
          </LogoContainer>
        </Container>
      </Parent>

      <ContentBlock background="#1f1f1f" inline={true} fullWidth>
        <FooterBottom>
          <ContentBlock inline center row>
            <div>
              <span style={{ marginRight: '1rem' }}>
                Webdesign &copy; 2022{' '}
              </span>
            </div>
            <div>
              <Link href="http://www.embeweb.be" blank type="text">
                Embeweb
              </Link>
              | <Link to="/Gdpr">GDPR</Link>
            </div>
          </ContentBlock>
        </FooterBottom>
      </ContentBlock>
    </>
  )
}

export default Footer

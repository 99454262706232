import React, { useEffect, useState } from 'react'
import Page from '../Page'
import FotoAlbum from '../common/FotoAlbum'
/*
const images = [
  'https://www.harmonieviversel.be/uploads/album/clubkampioenschap%202022_0001.JPG3178171443',
  'https://www.harmonieviversel.be/uploads/album/clubkampioenschap%202022_0004.JPG3427857250',
  'https://www.harmonieviversel.be/uploads/album/clubkampioenschap%202022_0005b.jpg2378790773',
  'https://www.harmonieviversel.be/uploads/album/clubkampioenschap%202022_0007.JPG3180166482',
  'https://www.harmonieviversel.be/uploads/album/clubkampioenschap%202022_0008.JPG3610852057',
  'https://www.harmonieviversel.be/uploads/album/clubkampioenschap%202022_0011.JPG3709755604',
  'https://www.harmonieviversel.be/uploads/album/clubkampioenschap%202022_0015.JPG3640769020',
  'https://www.harmonieviversel.be/uploads/album/clubkampioenschap%202022_0018b.jpg3514970965',
  'https://www.harmonieviversel.be/uploads/album/clubkampioenschap%202022_0032.JPG2298684268',
  'https://www.harmonieviversel.be/uploads/album/clubkampioenschap%202022_0034.JPG3281509252',
  'https://www.harmonieviversel.be/uploads/album/clubkampioenschap%202022_0035.JPG3569839078',
  'https://www.harmonieviversel.be/uploads/album/clubkampioenschap%202022_0037.JPG3268780948',
  'https://www.harmonieviversel.be/uploads/album/clubkampioenschap%202022_0039.JPG1922227237',
  'https://www.harmonieviversel.be/uploads/album/clubkampioenschap%202022_0042b.jpg1786403071',
  'https://www.harmonieviversel.be/uploads/album/clubkampioenschap%202022_0045b.jpg3191289296',
  'https://www.harmonieviversel.be/uploads/album/clubkampioenschap%202022_0048.JPG3564081045',
  'https://www.harmonieviversel.be/uploads/album/clubkampioenschap%202022_0049.JPG2172870678',
  'https://www.harmonieviversel.be/uploads/album/clubkampioenschap%202022_0051.JPG2368725135',
]
const images2 = [
  'https://www.harmonieviversel.be/uploads/album/clubfeest%202022%2010.jpg3221307380',
  'https://www.harmonieviversel.be/uploads/album/clubfeest%202022%2011.jpg1871151761',
  'https://www.harmonieviversel.be/uploads/album/clubfeest%202022%2013.jpg3682388016',
  'https://www.harmonieviversel.be/uploads/album/clubfeest%202022%2014.jpg3657255239',
  'https://www.harmonieviversel.be/uploads/album/clubfeest%202022%2015.jpg2795053832',
  'https://www.harmonieviversel.be/uploads/album/clubfeest%202022%2016.JPG2828761516',
  'https://www.harmonieviversel.be/uploads/album/clubfeest%202022%2017.jpg1701762433',
  'https://www.harmonieviversel.be/uploads/album/clubfeest%202022%2018.JPG2193168202',
  'https://www.harmonieviversel.be/uploads/album/clubfeest%202022%2019.jpg3583111522',
  'https://www.harmonieviversel.be/uploads/album/clubfeest%202022%2020.jpg1753185221',
  'https://www.harmonieviversel.be/uploads/album/clubfeest%202022%2021.jpg2644839647',
  'https://www.harmonieviversel.be/uploads/album/clubfeest%202022%2022.JPG3355533927',
  'https://www.harmonieviversel.be/uploads/album/clubfeest%202022%2023.jpg1734619622',
  'https://www.harmonieviversel.be/uploads/album/clubfeest%202022%2024.JPG1656516565',
  'https://www.harmonieviversel.be/uploads/album/clubfeest%202022%2025.jpg1656516566',
  'https://www.harmonieviversel.be/uploads/album/clubfeest%202022%2026.jpg1656516567',
  'https://www.harmonieviversel.be/uploads/album/clubfeest%202022%2027.jpg1656516568',
  'https://www.harmonieviversel.be/uploads/album/clubfeest%202022%2028.jpg1656516569',
  'https://www.harmonieviversel.be/uploads/album/clubfeest%202022%2029.jpg1656516570',
  'https://www.harmonieviversel.be/uploads/album/clubfeest%202022%2030.jpg1656516570',
  'https://www.harmonieviversel.be/uploads/album/clubfeest%202022%2031.jpg1656516598',
  'https://www.harmonieviversel.be/uploads/album/clubfeest%202022%2032.jpg1656516600',
  'https://www.harmonieviversel.be/uploads/album/clubfeest%202022%2033.jpg1656516601',
  'https://www.harmonieviversel.be/uploads/album/clubfeest%202022%2034.jpg1656516602',
  'https://www.harmonieviversel.be/uploads/album/clubfeest%202022%2035.jpg1656516603',
  'https://www.harmonieviversel.be/uploads/album/clubfeest%202022%2036.jpg1656516604',
  'https://www.harmonieviversel.be/uploads/album/clubfeest%202022%2037.jpg1656516605',
  'https://www.harmonieviversel.be/uploads/album/clubfeest%202022%2038.jpg1656516756',
  'https://www.harmonieviversel.be/uploads/album/clubfeest%202022%2039.jpg1656516757',
  'https://www.harmonieviversel.be/uploads/album/clubfeest%202022%2040.jpg1656516758',
  'https://www.harmonieviversel.be/uploads/album/clubfeest%202022%2041.jpg1656516759',
  'https://www.harmonieviversel.be/uploads/album/clubfeest%202022%2042.jpg1656516760',
  'https://www.harmonieviversel.be/uploads/album/clubfeest%202022%2043.jpg1656516761',
  'https://www.harmonieviversel.be/uploads/album/clubfeest%202022%2044.jpg1656516762',
  'https://www.harmonieviversel.be/uploads/album/clubfeest%202022%2045.jpg1656516805',
  'https://www.harmonieviversel.be/uploads/album/clubfeest%202022%2046.jpg1656516806',
  'https://www.harmonieviversel.be/uploads/album/clubfeest%202022%2047.jpg1656516807',
  'https://www.harmonieviversel.be/uploads/album/clubfeest%202022%2048.jpg1656516808',
  'https://www.harmonieviversel.be/uploads/album/clubfeest%202022%2049.jpg1656516808',
  'https://www.harmonieviversel.be/uploads/album/clubfeest%202022%2050.jpg1656516809',
  'https://www.harmonieviversel.be/uploads/album/clubfeest%202022%2051.jpg1656516810',
  'https://www.harmonieviversel.be/uploads/album/clubfeest%202022%2052.jpg1656516811',
  'https://www.harmonieviversel.be/uploads/album/clubfeest%202022%2053.jpg1656516812',
  'https://www.harmonieviversel.be/uploads/album/clubfeest%202022%2054.jpg1656516813',
  'https://www.harmonieviversel.be/uploads/album/clubfeest%202022%2055.jpg1656516814',
]
/*
const images2 = [
  'https://www.harmonieviversel.be/uploads/album/237992745_1756057354784446_2222382682017730437_n.jpg1629329138',
  'https://www.harmonieviversel.be/uploads/album/237992745_1756057354784446_2222382682017730437_n.jpg1629329138',
  'https://www.harmonieviversel.be/uploads/album/237992745_1756057354784446_2222382682017730437_n.jpg1629329138',
  'https://www.harmonieviversel.be/uploads/album/237992745_1756057354784446_2222382682017730437_n.jpg1629329138',
]¨
*/

const Album = () => {
  const [albumData, setAlbumData] = useState('')
  const [albums, setAlbums] = useState('')
  const getAlbumData = async () => {
    fetch('https://www.ttcsmash.be/user/api/getAllImages', {
      mode: 'cors',
    })
      .then((response) => response.json())
      .then((data) => {
        setAlbumData(data)
        console.log(data)
      })
      .catch((err) => console.log(err))
  }
  useEffect(() => {
    getAlbumData()
  }, [])

  useEffect(() => {
    if (albumData) {
      // concat albums and subalbums
      const albumsWithSubs = albumData.albums.map((album) => {
        const subAlbums = albumData.subAlbums.filter((subAlbum) => {
          return subAlbum.albums_id === album.id
        })

        return { title: album.title, subAlbums }
      })

      // fill images on subalbum level
      const filledAlbumsWithSubs = albumsWithSubs.map((album) => {
        const subAlbums = album.subAlbums.map((subAlbum) => {
          let images = []
          images = albumData.images.filter((image) => {
            return image.sub_albums_id === subAlbum.id
          })

          return { subAlbum, images }
        })
        return { title: album.title, subAlbums }
      })

      // fill images on album level
      const filledAlbums = filledAlbumsWithSubs.map((filledAlbum) => {
        const albumImages = filledAlbum.subAlbums.reduce((prev, curr) => {
          curr.images.forEach((image) => {
            prev.images.push(image)
          })
          return { images: prev.images }
        })

        const albumImageUrls = albumImages.images.map((image) => {
          return {
            url: 'https://www.ttcsmash.be/user/uploads/album/' + image.image,
            thumb:
              'https://www.ttcsmash.be/user/uploads/album/thumbs/' +
              image.image,
          }
        })

        return {
          title: filledAlbum.title,
          images: albumImageUrls,
        }
      })
      setAlbums(filledAlbums)
    }
  }, [albumData])

  return (
    <Page pageTitle="Fotoalbum">
      <FotoAlbum
        albums={albums}
        /*
        albums={[
          { images: images2, title: 'Clubfeest 2022' },
          { images, title: 'Clubkampioenschap 2022' },
        ]}*/
      />
    </Page>
  )
}

export default Album

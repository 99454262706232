import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import propTypes from 'prop-types'
import { mediaQuery } from '../utils/mediaQuery'

const LinkBlockContainer = styled(Link)`
  background: ${(props) => props.bg};
  color: ${(props) => props.color};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 22%;
  margin: 0 1.5%;
  height: 20rem;
  ${mediaQuery({
    width: 'sm',
    style: `
    height: 10rem;
  `,
  })};
  font-weight: bold;
  transition: background 0.3s;
  font-size: ${(props) => props.theme.text.normal.fontSize};
  line-height: ${(props) => props.theme.text.normal.lineHeight};
  margin-bottom: 2rem;

  &:hover {
    cursor: pointer;
    background: #1f1f1f;
    text-decoration: none;
    color: ${(props) => props.color};
  }
  ${mediaQuery({
    width: 'xl',
    style: `
    width: 30%;
  `,
  })};

  ${mediaQuery({
    width: 'lg',
    style: `
    width: 47%;
  `,
  })};
  ${mediaQuery({
    width: 'sm',
    style: `
    width: 100%;
  `,
  })};
`

const LinkBlocksContainer = styled.div`
  max-width: ${(props) => props.width};
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  margin-bottom: 14rem;
`

const LinkBlock = ({ children, bg, to, color }) => {
  return (
    <LinkBlockContainer to={to} bg={bg} color={color}>
      {children}
    </LinkBlockContainer>
  )
}

const LinkBlocks = ({ children, width }) => {
  return <LinkBlocksContainer width={width}>{children}</LinkBlocksContainer>
}

LinkBlocks.propTypes = {
  children: propTypes.object,
  width: propTypes.string,
}

LinkBlocks.defaultProps = {}

LinkBlock.propTypes = {
  children: propTypes.object,
  to: propTypes.string,
  bg: propTypes.string,
  color: propTypes.string,
}

LinkBlock.defaultProps = {}

export { LinkBlocks, LinkBlock }

import React from "react";
import styled from "styled-components";
import { FacebookProvider, Page } from "react-facebook";
import { ContentBlock, ContentBlockContainer } from ".";

const appIdHarmonie = 433913383444661;
const appIdJeugdorkest = 359498977585000;

const Container = styled.div``;
const FacebookBlock = () => {
  return (
    <ContentBlock backgroundColor="#1f1f1f" size="large">
      <h2>Het laatste nieuws via facebook</h2>
      <ContentBlockContainer inlineBlock>
        <ContentBlock inline padding="right">
          <FacebookProvider appId={appIdHarmonie}>
            <Page
              href="https://www.facebook.com/KHEVViversel"
              tabs="timeline"
            />
          </FacebookProvider>
        </ContentBlock>
        <ContentBlock inline>
          <div style={{ marginBottom: "2rem" }}>
            <FacebookProvider appId={appIdHarmonie}>
              <Page
                href="https://www.facebook.com/JeugdorkestViversel"
                tabs="timeline"
              />
            </FacebookProvider>
          </div>
        </ContentBlock>
      </ContentBlockContainer>
    </ContentBlock>
  );
};

export default FacebookBlock;

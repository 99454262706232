import React from 'react'
import {
  Link,
  useMatch,
  useResolvedPath,
  useLocation,
  Link as NavLink,
} from 'react-router-dom'
import styled from 'styled-components'
import propTypes from 'prop-types'
import { mediaQuery, screenWidth } from '../../../utils/mediaQuery'

const navigationStyle = `
padding:0 2rem;
color: #fefefe;
color: white;
text-decoration: none;
background: none;
border: none;
margin-left: 1.5rem;
opacity: 1;
top: 0;
&:last-of-type {
  margin-right: -1rem;
  
}
&:hover,
&:visited,
&:active,
&:target,
&:focus,
&:focus-visible,
&:focus-within {
  background: none;
  border: none;
  text-decoration: none;
  box-shadow: none;
}

&:hover {
  background:  #238760;
  height:100%;
  ${mediaQuery({
    overflow: 'hidden',
    width: 'xxl',
    style: `
    height:auto;
 
`,
  })}
}
}

`
const NavigationLink = styled(NavLink)`
  ${navigationStyle}
  font-size: ${(props) => props.theme.nav.fontSize};
  line-height: ${(props) => props.theme.nav.lineHeight};
  position: relative;

  &.active {
    background: #1f1f1f;
    ${mediaQuery({
      overflow: 'hidden',
      width: 'xxl',
      style: `
      background: rgb(43, 143, 104)
  
    `,
    })};
    &:before {
      content: '';
      position: absolute;
      height: 0.3rem;
      bottom: 0;
      left: 0;
      width: 100%;
      background: ${(props) => props.theme.color.white};
      ${mediaQuery({
        overflow: 'hidden',
        width: 'xxl',
        style: `
        background:none;
        background: rgb(43, 143, 104)

       
      `,
      })};
    }
  }

  ${mediaQuery({
    overflow: 'hidden',
    width: 'xxl',
    style: `
    &. {
      &:before{
        display:none;
      }
    }
    line-height:6rem;
`,
  })}
`
const CustomLink = ({ children, to, className, ...props }) => {
  const elements = React.Children.toArray(children)
  let element
  if (elements.length >= 1) {
    element = elements[0]
  } else {
    element = elements
  }

  const location = useLocation()
  const match =
    to.indexOf(location.pathname) === 0 && location.pathname === to
      ? true
      : false

  return (
    <>
      <NavigationLink
        to={to}
        className={
          match ? 'btn btn-primary btn-lg active' : 'btn btn-primary btn-lg'
        }
        {...props}
      >
        {children}
      </NavigationLink>
    </>
  )
}

export default CustomLink

CustomLink.propTypes = {
  children: propTypes.any,
  className: propTypes.string,
  to: propTypes.any,
}

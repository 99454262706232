import React, { useEffect, useState } from 'react'
import propTypes from 'prop-types'
import { Parallax, useController } from 'react-scroll-parallax'
import styled from 'styled-components'
import ParallaxCache from './ParallaxCache'
import { screenWidth } from '../../../utils/mediaQuery'

const ImageContainerStyle = styled.div`
  width: 100%;
  height: 25rem;
  z-index: 99;
  left: 0;
  opacity: 0.15;
  z-index: 0;
`

const ImageContainer = ({ height, src }) => {
  const { parallaxController } = useController()

  const [imageUrlState, setImageUrlState] = useState()
  const [loadImage, setLoadImage] = useState(false)

  function handleLoad() {
    setLoadImage(true)

    // load image only if imageUrl is different from what is already loaded
    // onload is the parallaxController only updated when image is changing
    // prevent flickering + update is going to update the parallax for the new image
    // the new image will be loaded properly
    if (src != imageUrlState) {
      parallaxController.update()
    }
  }

  useEffect(() => {
    if (loadImage) {
      setImageUrlState(src)
    }
  }, [loadImage])

  return (
    <>
      <ImageContainerStyle key={src}>
        <Parallax y={[-50, 50]} tagOuter="figure">
          <img
            src={src}
            style={{ width: '100%', visibility: 'hidden' }}
            onLoad={handleLoad}
          />
          <div
            style={{
              opacity: 1,
              position: 'absolute',
              height: '35rem',
              width: '100%',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundImage: `url(${src})`,
              left: 0,
              top: '0',
            }}
          />
        </Parallax>
      </ImageContainerStyle>

      <ParallaxCache />
    </>
  )
}

ImageContainer.propTypes = {
  height: propTypes.string,
  src: propTypes.string,
}

ImageContainer.defaultProps = {
  height: 50,
}

export default ImageContainer

import React, { useState, useEffect } from 'react'
import propTypes from 'prop-types'
import FormELement from './FormElement'
import styled from 'styled-components'

const TextArea = ({ label, required, validation, notShowed, ...props }) => {
  const TextAreaElem = styled.textarea`
    width: 100%;
    font-size: 1.5rem !important;
  `
  return (
    <FormELement
      label={label}
      validation={validation}
      required={required}
      notShowed={notShowed}
      {...props}
    >
      <TextAreaElem rows="8" cols="100"></TextAreaElem>
    </FormELement>
  )
}

export default TextArea

TextArea.propTypes = {
  label: propTypes.string.isRequired,
  required: propTypes.bool,
  notShowed: propTypes.bool,
  validation: (propTypes.shape = {
    rules: propTypes.array,
    excludes: propTypes.array,
  }),
}

TextArea.defaultProps = {
  validation: {
    rules: [],
    excludes: [],
  },
  required: false,
  notShowed: false,
}

import React from 'react'
import propTypes from 'prop-types'
import { Input } from './'

const Email = ({ label, ...props }) => {
  return (
    <Input
      {...props}
      label={label}
      validation={{ rules: ['^([A-Za-z0-9_\\-\\.])+@'] }}
    />
  )
}

export default Email

Email.propTypes = {
  label: propTypes.string.isRequired,
  props: propTypes.any,
}

Email.defaultProps = {
  label: 'Email',
}

import React from 'react'
import { ContentBlock } from '../../components/common'
import Page from '../../components/Page'
import { Link } from '../../components/style-components'
import { Form } from './common/form'
import { FormGroup, Text, Email } from './common/form/form-elements'
import Date from './common/form/form-elements/Date'
import Dropdown from './common/form/form-elements/Dropdown'
import TextArea from './common/form/form-elements/TextArea'
import Protected from './Protected'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'

const Backoffice = () => {
  return (
    <Protected>
      <Page pageTitle="Stuur je verslag in">
        {' '}
        <ContentBlock>
          {' '}
          <p>Stuur je verslag door via onderstaande formulier:</p>
        </ContentBlock>
        <ContentBlock>
          <Form>
            <FormGroup>
              <Text label="Naam" name="Naam" required notShowed />
              <Text label="Voornaam" name="Voornaam" required />
            </FormGroup>
            <FormGroup>
              <Email required notShowed name="Email" />
            </FormGroup>
            <FormGroup>
              <Date label="Datum wedstrijd" name="Datum" required />

              <Dropdown label="ploeg" name="Ploeg">
                <option value="A-ploeg">A-ploeg</option>
                <option value="B-ploeg">B-ploeg</option>
                <option value="C-ploeg">C-ploeg</option>
                <option value="D-ploeg">D-ploeg</option>
              </Dropdown>
            </FormGroup>

            <FormGroup fullWidth>
              <TextArea label="Artikel" name="Artikel " required />
            </FormGroup>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Link
                icon={{ name: faPaperPlane }}
                type="buttonTertiary"
                href="/form/submit"
              >
                {' '}
                Verzenden
              </Link>
            </div>
          </Form>
        </ContentBlock>
      </Page>
    </Protected>
  )
}
export default Backoffice

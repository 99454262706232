import React from 'react'
import propTypes from 'prop-types'
import {
  LightgalleryProvider,
  LightgalleryItem,
  useLightgallery,
} from 'react-lightgallery'
import ContentBlock from './ContentBlock'

const albumSize = {
  small: '120rem',
  large: '120rem',
}

const pictureSize = {
  small: '30rem',
  large: '40rem',
}

const PhotoItem = ({ src, thumb, size }) => {
  return (
    <div
      style={{
        maxWidth: pictureSize[size],
        width: '100%',
        display: 'flex',
        flexFlow: 'row wrap',
      }}
    >
      <LightgalleryItem group="niews" src={src} thumb={thumb}>
        <img src={thumb} style={{ width: '100%' }} />
      </LightgalleryItem>
    </div>
  )
}

const SingleAlbum = ({ src, thumb, size }) => {
  {
    /*Example: https://codesandbox.io/s/mo45kpo92j?fontsize=14&file=/src/index.js:2967-4797*/
  }
  const content = src ? (
    <ContentBlock fullWidth>
      <LightgalleryProvider
        onBeforeOpen={() => console.info('onBeforeOpen')}
        onAfterOpen={() => console.info('onAfterOpen')}
        onSlideItemLoad={() => console.info('onSlideItemLoad')}
        onBeforeSlide={() => console.info('onBeforeSlide')}
        onAfterSlide={() => console.info('onAfterSlide')}
        onBeforePrevSlide={() => console.info('onBeforePrevSlide')}
        onBeforeNextSlide={() => console.info('onBeforeNextSlide')}
        onDragstart={() => console.info('onDragstart')}
        onDragmove={() => console.info('onDragmove')}
        onDragend={() => console.info('onDragend')}
        onSlideClick={() => console.info('onSlideClick')}
        onBeforeClose={() => console.info('onBeforeClose')}
        onCloseAfter={() => console.info('onCloseAfter')}
      >
        <PhotoItem src={src} thumb={thumb} size={size} />
      </LightgalleryProvider>
    </ContentBlock>
  ) : (
    ''
  )

  return content || ''
}

SingleAlbum.propTypes = {
  src: propTypes.string,
  thumb: propTypes.string,
  size: (propTypes.oneOf = ('small', 'large')),
}

SingleAlbum.defaultProps = {
  size: 'small',
}

PhotoItem.propTypes = {
  src: propTypes.string.isRequired,
  thumb: propTypes.string,
  size: propTypes.string,
}
export default SingleAlbum

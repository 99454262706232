import React from 'react'
import propTypes from 'prop-types'
import styled from 'styled-components'
const Container = styled.div`
  width: 100%;
`
const Form = ({ children }) => {
  return <Container>{children}</Container>
}

export default Form

Form.propTypes = {
  children: propTypes.any,
}

import React from 'react'
import propTypes from 'prop-types'
import {
  LightgalleryProvider,
  LightgalleryItem,
  useLightgallery,
} from 'react-lightgallery'
import ContentBlock from './ContentBlock'

const albumSize = {
  small: '120rem',
  large: '120rem',
}

const pictureSize = {
  small: '30rem',
  large: '40rem',
}

const PhotoItem = ({ image, thumb, group, size }) => {
  console.log(image.thumb)
  return (
    <div
      style={{
        maxWidth: pictureSize[size],
        width: '100%',
        padding: '5px',
        display: 'flex',
        flexFlow: 'row wrap',
      }}
    >
      <LightgalleryItem group={group} src={image.url} thumb={image.thumb}>
        <img src={image.thumb} style={{ width: '100%' }} />
      </LightgalleryItem>
    </div>
  )
}

const FotoAlbum = ({ albums, size }) => {
  {
    /*Example: https://codesandbox.io/s/mo45kpo92j?fontsize=14&file=/src/index.js:2967-4797*/
  }
  const content = albums ? (
    <ContentBlock fullWidth>
      <LightgalleryProvider
        onBeforeOpen={() => console.info('onBeforeOpen')}
        onAfterOpen={() => console.info('onAfterOpen')}
        onSlideItemLoad={() => console.info('onSlideItemLoad')}
        onBeforeSlide={() => console.info('onBeforeSlide')}
        onAfterSlide={() => console.info('onAfterSlide')}
        onBeforePrevSlide={() => console.info('onBeforePrevSlide')}
        onBeforeNextSlide={() => console.info('onBeforeNextSlide')}
        onDragstart={() => console.info('onDragstart')}
        onDragmove={() => console.info('onDragmove')}
        onDragend={() => console.info('onDragend')}
        onSlideClick={() => console.info('onSlideClick')}
        onBeforeClose={() => console.info('onBeforeClose')}
        onCloseAfter={() => console.info('onCloseAfter')}
      >
        {albums.map((album, i) => (
          <div
            key={i}
            style={{
              width: '100%',
              margin: '0 auto',
              marginTop: i >> 0 ? '8rem' : '0',
              display: 'flex',
              flexFlow: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <h2 style={{ textAlign: 'center' }}>{album.title}</h2>
            <div
              style={{
                maxWidth: albumSize[size],
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexFlow: 'row wrap',
                margin: '0 auto',
              }}
            >
              {album.images.map((p, i) => (
                <PhotoItem key={i} image={p} group={album.title} size={size} />
              ))}
            </div>
          </div>
        ))}
      </LightgalleryProvider>
      ;
    </ContentBlock>
  ) : (
    ''
  )

  return content || ''
}

FotoAlbum.propTypes = {
  albums: (propTypes.shape = {
    images: propTypes.object,
    title: propTypes.string,
    textNote: propTypes.string,
  }),
  size: (propTypes.oneOf = ('small', 'large')),
}

FotoAlbum.defaultProps = {
  size: 'small',
}

PhotoItem.propTypes = {
  image: propTypes.string.isRequired,
  thumb: propTypes.string,
  group: propTypes.string.isRequired,
  size: propTypes.string,
}
export default FotoAlbum

import React, { useEffect, useState } from 'react'
import { Slide } from 'react-slideshow-image'
import styled from 'styled-components'
import 'react-slideshow-image/dist/styles.css'
import propTypes from 'prop-types'
import { mediaQuery } from '../../../utils/mediaQuery'

const slideImages = [
  'images/slide-images/ttcsmash-image1.jpg',
  'images/slide-images/ttcsmash-image2.jpg',
  'images/slide-images/ttcsmash-image3.jpg',
  'images/slide-images/ttcsmash-image4.jpg',
]

const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 5rem;
  position: relative;
  z-index: 2;
  margin-bottom: 4rem;
  .each-slide > div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    height: 350px;
  }

  .each-slide span {
    padding: 20px;
    font-size: 20px;
    background: #efefef;
    text-align: center;
  }
  ${mediaQuery({
    width: 'xxl',
    style: `
    margin:3rem 1.5rem;

    margin-top:5rem;
    margin-bottom:0rem;
  `,
  })}
  ${mediaQuery({
    width: 'lg',
    style: `
    margin:3rem 1.5rem;
    margin-top:3rem;
    margin-bottom:0;
  `,
  })}
`

const Wrapper = styled.div`
  width: 100%;
  max-width: 120rem;
  ${mediaQuery({
    width: 'xxl',
    style: `
    max-width: 100rem;
  `,
  })}
`

const style = {
  textAlign: 'center',
  fontSize: '30px',
}

const Slideshow = ({ slidesToShow }) => {
  const properties = {
    duration: 3000,
    slidesToShow: slidesToShow,
    slidesToScroll: slidesToShow,
    autoplay: false,
    indicators: true,
  }

  return (
    <Container>
      <Wrapper>
        <Slide {...properties} easing="ease">
          {slideImages.map((imageUrl, index) => {
            return (
              <div style={style} className="each-slide" key={index}>
                <div
                  style={{
                    backgroundImage: `url(${imageUrl})`,
                    marginRight:
                      index % 2 == 0 && slidesToShow > 1 ? '2rem' : 0,
                  }}
                ></div>
              </div>
            )
          })}
        </Slide>
      </Wrapper>
    </Container>
  )
}

Slideshow.propTypes = {
  slidesToShow: propTypes.number,
}

Slideshow.defaultProps = {
  slidesToShow: 1,
}

export default Slideshow

import React from "react";
import styled from "styled-components";
import { Link } from "../../style-components";

const logoImage1 =
  "https://www.harmonieviversel.be/layout/img/logo_harmonie_eigenvolk_viversel_small-min.png";
const logoImage2 =
  "https://www.harmonieviversel.be/layout/img/logo_jeugdorkest_viversel_small-min.png";

const Wrapper = styled.div`
  width: 80rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 auto;
  position: relative;
  margin-top: -6rem;
`;

const Container = styled.div`
  display: flex;
  flex-flow: column;
  text-align: center;
  align-items: center;
`;
const LogoContainer = () => {
  return (
    <Wrapper>
      <Container>
        <img
          style={{ height: "11.5rem", marginBottom: "2rem", marginTop: "1rem" }}
          src={logoImage1}
          alt=""
        />
        <Link type="buttonDefault">Meer info over de harmonie</Link>
      </Container>
      <Container>
        <img
          style={{ height: "12.5rem", marginBottom: "2rem" }}
          src={logoImage2}
          color="dark"
          alt=""
        />
        <Link type="buttonDefault">Meer info over het Jeugdorkest</Link>
      </Container>
    </Wrapper>
  );
};

export default LogoContainer;

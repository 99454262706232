import React from 'react'
import News from '../modules/news/News'
import Page from '../Page'

const Nieuws = () => {
  return (
    <Page
      pageTitle="Nieuws"
      imageUrl="https://www.harmonieviversel.be/uploads/header/cover_harmonie1.jpg1583190402"
    >
      <News showAllArticles />
    </Page>
  )
}

export default Nieuws

import React from 'react'
import { LightgalleryProvider, LightgalleryItem } from 'react-lightgallery'
import PropTypes from 'prop-types'

import { TextImage } from '../style-components'

const SingleLightGallery = ({ image, thumb }) => (
  <LightgalleryProvider
    onBeforeOpen={() => console.info('onBeforeOpen')}
    onAfterOpen={() => console.info('onAfterOpen')}
    onSlideItemLoad={() => console.info('onSlideItemLoad')}
    onBeforeSlide={() => console.info('onBeforeSlide')}
    onAfterSlide={() => console.info('onAfterSlide')}
    onBeforePrevSlide={() => console.info('onBeforePrevSlide')}
    onBeforeNextSlide={() => console.info('onBeforeNextSlide')}
    onDragstart={() => console.info('onDragstart')}
    onDragmove={() => console.info('onDragmove')}
    onDragend={() => console.info('onDragend')}
    onSlideClick={() => console.info('onSlideClick')}
    onBeforeClose={() => console.info('onBeforeClose')}
    onCloseAfter={() => console.info('onCloseAfter')}
  >
    <LightgalleryItem src={thumb}>
      <TextImage src={image} />
    </LightgalleryItem>
  </LightgalleryProvider>
)

SingleLightGallery.propTypes = {
  image: PropTypes.string.isRequired,
  thumb: PropTypes.string.isRequired,
}

export default SingleLightGallery

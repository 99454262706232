import React, { useState } from 'react'
import Page from '../Page'
import { ContentBlock, ContentBlockContainer } from '../common'
import Link from '../style-components/Link'

const Links = () => {
    const [fetchedTtlk, setFetchedttlk] = useState([])
 const fetchTtlk = async () => {
   fetch('https://www.ttcsmash.be/user/api/getAllTtlk', {
     mode: 'cors',
   })
     .then((response) => response.json())
     .then((data) => {

       setFetchedttlk(data.map((item, index) => {
        return (
            {
              href: item.url,
              title: item.title
            }
          )
       }))
     })
      .catch((err) => console.log(err))
    
  }

  fetchTtlk();

  return (
    <Page
      pageTitle="Interessante links"
      imageUrl="https://www.harmonieviversel.be/uploads/header/cover_harmonie1.jpg1583190402"
    >
      <ContentBlock textAlign="left">
        <h2>Enkele interessante links :</h2>
        <ul>
          <li>
            <Link blank type="text" href="https://competitie.vttl.be/">
              Competitie VTTL
            </Link>
            (kalender, uitslagen, rangschikking, …)
          </li> 
          <hr />
          <li>
            <Link blank type="text" href="https://pclktt.be">
              PCLKTT
            </Link>
            (officiële website PC Limburg-Kempen)
          </li>
          <hr />
        

          
          {
            fetchedTtlk.map(item => (
              <>
                         <li>
                            <Link
                    blank
                    type="text"
                    href={item.href}
                            >
                                             {item.title}
                            </Link>
                          </li>
                              <hr />
</>
       

                            )
  )
          }

         
          <li>
            <Link blank type="text" href="https://www.vttl.be">
              VTTL
            </Link>
            (algemene info Vlaamse tafeltennisliga)
          </li>
          <hr />
          <li>
            <Link blank type="text" href=" https://www.nieuwsheusdenzolder.be/">
              Nieuws Heusden-Zolder - alles wat leeft en gebeurt
            </Link>
          </li>
          <hr />
          <li>
            <Link
              blank
              type="text"
              href="https://www.google.com/url?q=https://www.vttl.be/reglementen&sa=D&source=calendar&usd=2&usg=AOvVaw17aRdv5IUvLPqc_-e8prsD"
            >
              VTTL reglementen
            </Link>
          </li>
          <hr />
          <li>
            <Link
              blank
              type="text"
              href="https://www.google.com/url?q=https://www.vttl.be/richtlijnen-tafeltennis&sa=D&source=calendar&usd=2&usg=AOvVaw1IzQy-pHM0ECVRyEv4F-mY"
            >
              Richtlijnen tafeltennis
            </Link>
            ( o.a. corona-maatregelen)
          </li>
          <hr />
          <li>
            <Link blank type="text" href="https://my.vttl.be/">
              My VTTL
            </Link>
            (UW persoonlijke gegevens, eerst even registreren)
          </li>
        </ul>
      </ContentBlock>
    </Page>
  )
}

export default Links

import React, { useEffect, useState } from 'react'
import { Route, Routes, Navigate, useNavigate } from 'react-router-dom'
import { ParallaxProvider } from 'react-scroll-parallax'
import { ThemeProvider, createGlobalStyle } from 'styled-components'
import HomePage from './HomePage'
import globalTheme from './globalTheme'

import {
  Contact,
  Member,
  Links,
  Gdpr,
  Nieuws,
  Album,
  Bestuur,
  History,
  EventPage,
  Info,
  PdfPage,
  ClubBoekjes,
} from './Pages'

import Backoffice from '../backoffice/components/Backoffice'
let url = window.location.href

function App() {
  const navigate = useNavigate()
  useEffect(() => {
    // old routes with hashes redirect to new route without #
    if (window.location.href.includes('#')) {
      navigate(window.location.hash.replace('#/', ''))
    }
  }, [])
  return (
    <ThemeProvider theme={globalTheme}>
      <ParallaxProvider>
        {window.location.href.includes('user') ? (
          navigate('/auth/login')
        ) : (
          <Routes style={{ margin: 0 }}>
            <Route exact path="/" element={<HomePage />} />
            <Route exact path="/events" element={<EventPage />} />
            <Route exact path="/events/:id" element={<EventPage />} />

            <Route exact path="/geschiedenis" element={<History />} />

            <Route exact path="/contact" element={<Contact />} />
            <Route exact path="/bestuur" element={<Bestuur />} />
            <Route exact path="/Album" element={<Album />} />
            <Route exact path="/smashkes" element={<PdfPage />} />
            <Route exact path="/clubboekjes" element={<ClubBoekjes />} />

            <Route exact path="/nuttige-informatie" element={<Info />} />
            <Route exact path="/lid-worden" element={<Member />} />

            <Route exact path="/linken" element={<Links />} />
            <Route exact path="/gdpr" element={<Gdpr />} />
            <Route exact path="/nieuws" element={<Nieuws />} />
            <Route exact path="/fotoalbum" element={<Album />} />
            <Route exact path="/backoffice" element={<Backoffice />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        )}
      </ParallaxProvider>
    </ThemeProvider>
  )
}

export default App

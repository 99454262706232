import React from 'react'
import Page from '../Page'
import Events from '../modules/events/Events'

const EventPage = () => {
  return (
    <Page pageTitle="Events">
      {' '}
      <Events showAllArticles />
    </Page>
  )
}

export default EventPage

import React from 'react'
import Page from '../Page'
import { ContentBlock, ContentBlockContainer } from '../common'
import Link from '../style-components/Link'

const Gdpr = () => {
  return (
    <Page
      pageTitle="GDPR"
      imageUrl="https://www.harmonieviversel.be/uploads/header/cover_harmonie1.jpg1583190402"
    >
      <ContentBlock textAlign="left">
        <h2>TTC Smash neemt de GDPR wetgeving ernstig</h2>
        <p>
          leden en niet-leden moeten beschermd worden tegen misbruik. Alle
          gegevens, foto&apos;s die wij in ons bezit hebben zijn toegankelijk
          voor een beperkt aantal personen. Voor video-materiaal vragen we
          steeds toestemming aan de betrokkenen. De website probeert zoveel
          mogelijk de privacy te beschermen. Ben je niet akkoord met gedeelde
          informatie: Gelieve ons dan te <Link to="/Contact">contacteren</Link>.
        </p>
      </ContentBlock>
      <ContentBlock textAlign="left">
        <h2>Wat is het doel van GDPR?</h2>
        <p>
          GDPR is de afkorting van General Data Protection Regulation, de nieuwe
          Europese privacywetgeving over het beschermen van persoonsgegevens,
          die op 25 mei 2018 van kracht werd in alle Europese lidstaten. De AVG
          is al op 24 mei 2016 in werking getreden, maar bedrijven en
          organisaties kregen evenwel tot 25 mei 2018 de tijd om zich aan te
          passen. GDPR is enerzijds om de betrokkenen terug (meer) controle te
          geven over die data die op hen betrekking hebben. Hierbij spelen hun
          fundamentele rechten, zoals het recht op privacy, een belangrijke rol.
          Concreet betekent dit dat deze nieuwe privacywetgeving streeft naar
          een betere bescherming van natuurlijke personen.
        </p>
      </ContentBlock>
    </Page>
  )
}

export default Gdpr

import React, { useEffect, useState } from 'react'
import Page from '../Page'
import Pdf from '../common/Pdf'
import { ContentBlock } from '../common'

const PdfPage = () => {
  const [pdfs, setPdfs] = useState([])
  const [fetchedPdfs, setFetchedPdfs] = useState('')
     
  useEffect(() => {
    // generate older pdfs
    const pdfLoopStart = 74
    const pdfLoopEnd = 35
    const exclude = 39

    let generatePdfs = []
    for (let i = pdfLoopStart; i >= pdfLoopEnd; i--) {
      if (i != exclude) {
        generatePdfs.push({
          url: `https://www.ttcsmash.be/pdf/Smashke${i}.pdf`,
          title: `Smashke${i}`,
        })
      }
    }

    //Get PDF's from database
     fetchPdfs();

    //merge in view
    setPdfs([...fetchedPdfs, ...generatePdfs])

  }, [fetchedPdfs]);

  

  const fetchPdfs = async () => {
    fetch('https://www.ttcsmash.be/user/api/getAllSmashkes', {
      mode: 'cors',
    })
      .then((response) => response.json())
      .then((data) => {
        setFetchedPdfs(data.map((item) => {
          
          return (
            {
              url: `https://www.ttcsmash.be/user/uploads/pdf/${item.filename}`,
              title: item.filename.replace(/\.pdf$/, '').replace(/^\d+/, '')
            }
          )
        }));

      })
      .catch((err) => console.log(err))
    
  }

  return (
    <Page
      pageTitle="Smashkes"
      imageUrl="https://www.harmonieviversel.be/uploads/header/cover_harmonie1.jpg1583190402"
    >
      <ContentBlock>
        <Pdf pdfs={pdfs} key={pdfs} />
      </ContentBlock>
    </Page>
  )
}

export default PdfPage

import React from 'react'
import propTypes from 'prop-types'

const Pdf = ({ pdfs }) => {
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexFlow: 'row wrap',
      }}
    >
      {pdfs.map((pdf, index) => (
        <a
          style={{
            width: '150px',
            padding: '2.5%',
            maxWidth: '50%',
            textAlign: 'center',
          }}
          target="_blank"
          rel="noreferrer"
          href={pdf.url}
          key={index}
        >
          <span>{pdf.title}</span>
          <img src="https://www.ttcsmash.be/pdf/pdf-logo.jpg" />
        </a>
      ))}
    </div>
  )
}
Pdf.propTypes = {
  pdfs: propTypes.array,
}

export default Pdf

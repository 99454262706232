import React from "react";
import propTypes from "prop-types";
import Text from "./Text";

const ListItem = ({ children, icon }) => {
  if (icon != undefined) {
    return (
      <li>
        <Text icon={icon} iconSize={icon.size}>
          {children}
        </Text>
      </li>
    );
  } else {
    return <li>{children}</li>;
  }
};

ListItem.propTypes = {
  children: propTypes.array,
  icon: propTypes.string,
};

export default ListItem;

import { useNavigate } from 'react-router-dom'
import propTypes from 'prop-types'
import isAuth from '../../services/isAuth'

const Protected = ({ isLoggedIn, children }) => {
  const navigate = useNavigate()
  if (!isLoggedIn) {
    window.location = 'https://ttcsmash.be/user'
  } else {
    return children
  }
}

Protected.propTypes = {
  children: propTypes.any,
  isLoggedIn: propTypes.bool,
}

Protected.defaultProps = {
  isLoggedIn: isAuth(),
}

export default Protected

// https://www.makeuseof.com/create-protected-route-in-react/

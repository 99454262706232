import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { faFacebookF } from '@fortawesome/free-brands-svg-icons'
import {
  faMapMarker,
  faCommentDots,
  faEnvelope,
  faBars,
  faUser,
} from '@fortawesome/free-solid-svg-icons'
import propTypes from 'prop-types'
//Custome components
import { ImageContainer, Navigation, LogoContainer } from './index'
import { mediaQuery, screenWidth } from '../../../utils/mediaQuery'
import globalTheme from '../../globalTheme'
// Styled components
import { Link, Text } from '../../style-components/index'
import isAuth from '../../../services/isAuth'

const TitleContainerHomePage = styled.div`
  width: 100%;
  height: 22rem;
  position: relative;
  padding: 7rem 0;

  ${mediaQuery({
    width: 'xxl',
    style: `
    height: 20rem;
    padding: 6rem 0;
  `,
  })}

  ${mediaQuery({
    width: 'sm',
    style: `
    height: 20rem;
    padding: 6rem 0;

`,
  })}
`

const HeaderTop = styled.div`
  height: 5rem;
  display: flex;
  justify-content: flex-start;
  position: absolute;
  top:-0.72rem;
  right: 0.2rem;
  z-index: 999;
  display:none;

  ${mediaQuery({
    width: 'xxl',
    style: `
    right: 4.5rem;
 
`,
  })}
  a {
    position:relative
    z-index:999 ;
    margin: 0 1rem;
    font-size:1.8rem !important;
    font-family: 'PT Sans Narrow', 'sans-serif' !important;
  }

  ${mediaQuery({
    width: 'xxl',
    style: `
    justify-content: flex-start;
 
`,
  })}

  align-items: center;
`

const TitleContainerPage = styled.div`
  width: 100%;
  height: 10rem;
  position: relative;
  overflow: hidden;
  padding: 2rem 0;
  ${mediaQuery({
    width: 'xxl',
    style: `
    height: 8rem;
 
`,
  })}
  ${mediaQuery({
    width: 'lg',
    style: `
    height: 8rem;
 
`,
  })}
`

const Container = styled.header`
  background: ${(props) => props.theme.color.primary};
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 20px;
  height: 100%;
  ${mediaQuery({
    width: 'xxl',
    style: `
    box-shadow:none;
 
`,
  })}
`

const Title = styled.h1`
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  font-size: 3.4rem;
  line-height: 2.6rem;
  color: #fefefe;
  margin-top: 1.5rem;
  ${mediaQuery({
    width: 'xxl',
    style: `
    margin-top:1rem;
    font-size: 3.0rem;
    line-height: 1.7rem;
 
`,
  })}
`

const TitlePage = styled.h1`
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  font-size: ${(props) => props.theme.title.medium.fontSize};
  line-height: ${(props) => props.theme.title.medium.lineHeight};
  color: #fefefe;
  margin-top: -1rem;
  ${mediaQuery({
    width: 'xxl',
    style: `
    margin-top:-1.5rem;
    font-size: 3.4rem;
    line-height: 3.9rem;
 
`,
  })}
`

const SubTitle = styled.span`
  font-size: ${(props) => props.theme.title.small.fontSize};
  line-height: ${(props) => props.theme.title.small.lineHeight};
  display: inline-block;
  position: relative;
  //top: -0.2rem;
  padding-top: 1.2rem;
  ${mediaQuery({
    width: 'xxl',
    style: `
  padding-top: 0.6rem;

    font-size: 2.1rem;
    line-height: 3.2rem;
 
`,
  })}
`
const TitleContainer = ({ pageTitle }) => {
  return (
    <>
      <TitleContainerPage>
        <Title>
          {pageTitle && <span>{pageTitle}</span>}
          {!pageTitle && <span>TTC Smash Heusden</span>}
        </Title>
        <div
          style={{
            position: 'absolute',
            top: '-10rem',
            marginTop: '0',
            width: '100%',
            height: '100%',
          }}
        >
          <ImageContainer src="images/tafeltennistafel-heusden-2.jpg" />
        </div>
      </TitleContainerPage>

      {/*
      {pageTitle && (
        <TitleContainerPage>
          <TitlePage>
            <span>{pageTitle}</span>
            
            <SubTitle>TTC Smash Heusden</SubTitle>
    
          </TitlePage>
          <div
            style={{
              position: 'absolute',
              top: '-10rem',
              marginTop: '0',
              width: '100%',
              height: '100%',
            }}
          >
            <ImageContainer src="images/tafeltennistafel-heusden-2.jpg" />
          </div>
        </TitleContainerPage>
      )}
          */}
    </>
  )
}

const Header = ({ pageTitle, imageUrl }) => {
  const [mobileView, setMobileView] = useState('nan')

  useEffect(() => {
    setMobileView(window.innerWidth < screenWidth.xxl)
    const handleResize = () => {
      setMobileView(window.innerWidth < screenWidth.xxl)
    }
    window.addEventListener('resize', handleResize)

    return (_) => {
      window.removeEventListener('resize', handleResize)
    }
  }, [window.innerWidth])

  return (
    <>
      <HeaderTop>
        <Link
          href={isAuth() ? '/backoffice' : 'https://ttcsmash.be/user'}
          hoverReversed
          type="text"
          icon={{ name: faUser, size: 'extraSmall', position: 'normal' }}
        >
          {isAuth() ? 'Stuur een verslag in' : 'login'}
        </Link>
      </HeaderTop>
      <Container>
        {mobileView && (
          <>
            {/*
            <HeaderTop>
              <Link
                href="mailto:info@ttcsmash.be"
                icon={{ name: faEnvelope, size: 'small' }}
                hoverReversed
                type="text"
              >
                info@ttcsmash.be
              </Link>
            </HeaderTop>
        */}
            <Navigation />
            <TitleContainer pageTitle={pageTitle} imageUrl={imageUrl} />
          </>
        )}
        {!mobileView && (
          <>
            {/*
            {' '}
            <HeaderTop>
              <Link
                href="mailto:info@ttcsmash.be"
                icon={{ name: faEnvelope }}
                hoverReversed
                type="text"
              >
                info@ttcsmash.be
              </Link>
            </HeaderTop>
             */}
            <Navigation />
            <TitleContainer pageTitle={pageTitle} imageUrl={imageUrl} />
          </>
        )}
      </Container>
    </>
  )
}

Header.propTypes = {
  pageTitle: propTypes.string,
  imageUrl: propTypes.string,
}
TitleContainer.propTypes = {
  pageTitle: propTypes.string,
  imageUrl: propTypes.string,
}

export default Header
